import Tool from "../../_models/tool-model";
import PEOPLE from '../../../assets/images/people.jpg'
import SMILEY from '../../../assets/images/digital-tools-icons/smily.png';
import ToolGallery from "../../../components/tools/gallery/tool-gallery";

const GALLERY = new Tool(
    6,
    'Gallery',
    'Gallery descrpition',
    SMILEY,
    'red',
    '157', {
        slug: 'gallery',
        items: [
            { url: PEOPLE, cover: PEOPLE },
            { url: PEOPLE },
            { url: PEOPLE }
        ]
    },
    '4112',
    ToolGallery
);

export default GALLERY;