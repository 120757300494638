import React from 'react';
import Card from '../m-card';
import { CardHeader, CardBody } from '../card-layout';
import CardFooter from '../card-layout/m-card-footer';
import { Heading, Paragraph, Image } from '../../../01_atoms';
import { HBox } from 'react-stylesheet';
import CardActions from '../card-layout/m-card-actions';
import { Row, Col } from 'react-grid-system';
import { gutters } from '../../../00_base/variables';


const Card3 = ({
    title,
    subtitle,
    description,
    image,
    actions,
    titleStyle,
    subtitleStyle,
    descriptionStyle,
    bodyStyle,
    actionsStyle,
    bodyHeight
}) => {
    console.log('actions', actions);
    return (
        <Row gutterWidth={0} style={{alignItems: 'center'}}>
            <Col xs={4}>
                <CardBody style={bodyStyle}>
                    {!!actions && <CardActions style={actionsStyle} items={actions} />}
                    <Image src={image} block />
                </CardBody>
            </Col>
            <Col xs={8}>
                <CardBody display={'block'} style={{...bodyStyle, padding:`0 ${gutters.sm}`}} backgroundColor={'transparent'}>
                    {!!title && <Heading tag={'h4'} style={titleStyle} value={title} />}
                    {!!subtitle && <Heading tag={'h6'} style={subtitleStyle} value={subtitle} />}
                    {!!description && <Paragraph style={descriptionStyle} value={description} />}
                </CardBody>
            </Col>
        </Row>
    )
}

export default Card3;