
const font_family = {
    primary: 'san-serif',
    secondary: 'serif'
};

const text_size_base = 1.6;
const text_scale_ratio = 1.2;

const sizes = {
    xxs: 1 + 'rem',
    xs: 1.2 + 'rem',
    sm: 1.6 + 'rem',
    md: 2 + 'rem',
    lg: 2.5 + 'rem',
    xl: 3.2 + 'rem',
    xxl: 3.6 + 'rem',
    xxxl: 4.2 + 'rem',
    jumbo: 6.2 + 'rem',
    extreme: 9.2 + 'rem',
    max: 20 + 'rem',
};

// const sizes = {
//     xs: text_size_base / (text_scale_ratio * text_scale_ratio) + 'em',
//     sm: text_size_base / text_scale_ratio + 'em',
//     md: text_size_base * text_scale_ratio + 'em',
//     lg: text_size_base * text_scale_ratio * text_scale_ratio + 'em',
//     xl: text_size_base * text_scale_ratio * text_scale_ratio * text_scale_ratio + 'em',
//     xxl: text_size_base * text_scale_ratio * text_scale_ratio * text_scale_ratio * text_scale_ratio + 'em',
//     xxxl: text_size_base * text_scale_ratio * text_scale_ratio * text_scale_ratio * text_scale_ratio * text_scale_ratio + 'em',
// };

const pageTitle = sizes.jumbo;

const headings = {
    h1: sizes.xxl,
    h2: sizes.xl,
    h3: sizes.lg,
    h4: sizes.md,
    h5: sizes.sm,
    h6: sizes.xs,
};

const font_size = {
    ...sizes,
    ...headings,
    pageTitle
};
export {font_size, font_family};