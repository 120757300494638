import React from 'react';

const TfLogo = ({width, height}) => {
    return (
        <div>
            <svg id="tf-logo" 
            width={width ? width : "40"}
            height={height ? height : '46'}
            viewBox="0 0 558.8 590.9">
                <path d="M317.8,587.8H241.9c-1.1,0-2.1-1.6-2.1-3.6V5c0-2,.9-3.6,2.1-3.6h75.8c1.1,0,2.1,1.6,2.1,3.6V584.3C319.8,586.2,318.9,587.8,317.8,587.8Z" style={{fill:'#231f20'}} />
                <rect x="418.9" y="142.7" width="80" height="197.48" rx="3" ry="3" transform="translate(217.4 700.3) rotate(-90)" style={{fill:'#f78e1e'}} />
                <rect x="418.9" y="-57.3" width="80" height="197.48" rx="3" ry="3" transform="translate(417.4 500.3) rotate(-90)" style={{fill:'#231f20'}} />
                <rect x="60.8" y="-57.6" width="80" height="198" rx="3" ry="3" transform="translate(59.3 142.2) rotate(-90)" style={{fill:'#f78e1e'}} />
            </svg>
        </div>
    )
}

export default TfLogo;