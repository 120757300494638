import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-grid-system';
import { HBox, Element } from 'react-stylesheet';
import styled from 'styled-components';

import {
    MdStar,
    MdPerson,
    MdKeyboardArrowDown,
    MdArrowBack,
    MdWatch,
} from "react-icons/md";





import call_screen from './images/call_screen.jpg';

import { font_size, gutters, colors } from '../../00_base/variables';
import { Image, Circle, Span } from '../../01_atoms';





class ToolContactList extends React.Component {
    render() {
        const { items } = this.props;

        const letters = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט', 'י', 'כ', 'ל', 'מ', 'נ', 'ס', 'ע', 'פ', 'צ', 'ק', 'ר', 'ש', 'ת'];

        // const items = toolData.items ? toolData.items : [];

        const phoneNumbers = [
            '052-9302033',
            '054-7516394',
            '053-6966920',
            '052-3215160',
            '052-5137860',
            '050-9302033',
            '055-8562719',
            '058-4237720',
            '054-5702712',
            '052-7926443',
            '052-8286610',
            '054-6691535',
            '056-9460455',
            '058-7160372',
            '053-9677979',
            '055-1931173',
            '057-1024042',
            '052-3451015',
            '055-6826558',
            '056-9302033',
            '053-4632202',
            '054-8982390',
            '054-2492684',
            '050-6155581',
            '050-6453499',
            '052-9302033',
            '054-7516394',
            '053-6966920',
            '052-3215160',
            '052-5137860',
            '050-9302033',
            '055-8562719',
            '058-4237720',
            '054-5702712',
            '052-7926443',
            '052-8286610',
            '054-6691535',
            '056-9460455',
            '058-7160372',
            '053-9677979',
            '055-1931173',
            '057-1024042',
            '052-3451015',
            '055-6826558',
            '056-9302033',
            '053-4632202',
            '054-8982390',
            '054-2492684',
            '050-6155581',
            '050-6453499',
            '052-9302033',
            '054-7516394',
            '053-6966920',
            '052-3215160',
            '052-5137860',
            '050-9302033',
            '055-8562719',
            '058-4237720',
            '054-5702712',
            '052-7926443',
            '052-8286610',
            '054-6691535',
            '056-9460455',
            '058-7160372',
            '053-9677979',
            '055-1931173',
            '057-1024042',
            '052-3451015',
            '055-6826558',
            '056-9302033',
            '053-4632202',
            '054-8982390',
            '054-2492684',
            '050-6155581',
            '050-6453499',
            '052-9302033',
            '054-7516394',
            '053-6966920',
            '052-3215160',
            '052-5137860',
            '050-9302033',
            '055-8562719',
            '058-4237720',
            '054-5702712',
            '052-7926443',
            '052-8286610',
            '054-6691535',
            '056-9460455',
            '058-7160372',
            '053-9677979',
            '055-1931173',
            '057-1024042',
            '052-3451015',
            '055-6826558',
            '056-9302033',
            '053-4632202',
            '054-8982390',
            '054-2492684',
            '050-6155581',
            '050-6453499',]; 

        return (
            <Element style={{flex: 1}}>

                {/* <ContactListHeader
                        isLoading={isLoading}
                        background={items[0] && items[0].color ? items[0].color : '#1e5a80'} /> */}

                <ListStyle style={listStyle}>
                    <Element width={'100%'}>
                        {items.filter(item => item.name).map((item, i) => {
                            console.log('item', item)
                            return (
                                <HBox key={i} className={i} alignItems={'center'} padding={`0 0 0 ${gutters.sm}`}>
                                    <Circle size={47}
                                        background={item.color ? item.color : '#1e5a80'}
                                        color={'#000'}>
                                        {item.icon ? <Image src={item.icon} /> :
                                            <MdPerson color={'#fff'} size={23} />}

                                    </Circle>
                                    <HBox alignItems='center'

                                        style={{ flex: 'auto' }}>

                                        <Link to={{
                                            pathname: `/phone-call`,
                                            name: item.name,
                                            audio: item.audio ? item.audio : 'https://admin.youdoadventures.com/wp-content/uploads/2019/04/no_answer.mp3',
                                            background: item.background ? item.background : call_screen,
                                            colors: this.props.colors,
                                            // urlId: urlId,
                                            number: phoneNumbers[i]
                                        }}
                                            style={listItem}>
                                            <HBox justifyContent={'space-between'} alignItems={'center'}>
                                                <Element>
                                                    <Span style={{
                                                        fontSize: font_size.md,
                                                        margin: '0'
                                                    }}>{item.name}</Span>
                                                    <Span fontSize={font_size.xs}
                                                        style={{ margin: '0' }}>
                                                        {phoneNumbers[i]}
                                                    </Span>
                                                </Element>
                                                <Element>
                                                    <MdKeyboardArrowDown size={30} />
                                                </Element>

                                            </HBox>
                                        </Link>
                                    </HBox>
                                </HBox>
                            );
                        })
                        }


                    </Element>

                    <Element width={'1.5rem'} padding={'1rem'}>
                        {letters.map((letter, l) => {
                            return (
                                <div key={l}
                                    style={{ color: items[0] && items[0].color ? items[0].color : '#000000' }}>
                                    {letter}
                                </div>
                            )
                        })}
                    </Element>
                </ListStyle>

            </Element>  
        )
    }
}



class ContactListHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0
        };

        this.changeFakeTab = this.changeFakeTab.bind(this);
    }

    changeFakeTab(index) {
        this.setState({
            activeTab: index
        })
    }

    render() {
        const { activeTab } = this.state;
        const { urlId, background, isLoading } = this.props;
        return (
            <Element width={'100%'}  zIndex={'100'} position={'fixed'} right={'0'} top={'4.5rem'}
                padding={'0'}>
                <HBox alignItems={'center'}>

                    <Link to={`/menu/?id=${urlId}`} style={{ padding: '0 1rem' }}>
                        <MdArrowBack width={'12px'}
                            height={'20px'}
                            color={'#fff'}
                            style={{ display: 'block' }} />
                    </Link>
                    <MdStar size={32}
                        color={'rgba(255,255,255, .6)'}
                        onClick={() => {
                            this.changeFakeTab(0)
                        }}
                        style={{
                            margin: '0 .3rem',
                            // paddingBottom: '.5rem',
                            borderBottom: '.2rem solid transparent',
                            ...activeTab === 0 && active
                        }} />

                    <MdWatch size={25}
                        color={'rgba(255,255,255, .6)'}
                        data-index={'1'}
                        onClick={() => {
                            this.changeFakeTab(1)
                        }}
                        style={{
                            margin: '0 .5rem',
                            paddingBottom: '.5rem',
                            borderBottom: '.2rem solid transparent',
                            ...activeTab === 1 && active
                        }} />

                    {/* <IconLamp width={25}
                        height={25}
                        color={'rgba(255,255,255, .6)'}
                        data-index={'2'}
                        onClick={() => {
                            this.changeFakeTab(2)
                        }}
                        style={{
                            paddingBottom: '.5rem',
                            borderBottom: '.2rem solid transparent',
                            marginRight: '.5rem',
                            ...activeTab === 2 && active
                        }} /> */}
                </HBox>

            </Element>
        )
    }
}

const ListStyle = styled.div`
&::-webkit-scrollbar {
    display: none;
}

&::-webkit-scrollbar-track {
    display: none;
}

&::-webkit-scrollbar-thumb {
    display: none;
}
`;
const listStyle = {
    padding: '0 1rem',
    zIndex: '3',
    overflow: 'scroll',
    position: 'relative',
    // height: calc(100vh - 7.5rem);
    // height: 100vh;
    top: '0',
     borderLeftTopRadius: '2rem',
    borderRightTopRadius: '2rem',
    display: 'flex',
    
};


const listItem = {
    display: 'block',
    width: '100%',
    fontSize: font_size.md,
    color: '#000',
    textDecoration: 'none',
    marginRight: gutters.sm,
    paddingTop: gutters.xs,
    paddingBottom: gutters.xs,
    borderBottom: '1px solid'
};

const active = {
    color: '#fff',
    borderBottom: '.3rem solid #fff'
};

export default ToolContactList;