import Tool from "../../_models/tool-model";
import ToolTrivia from "../../../components/tools/trivia/";
import TRIVIA_ICON from '../../../assets/images/digital-tools-icons/trivia.png';

const TRIVIA = new Tool(
    14,
    'Trivia',
    'Trivia descrpition',
    TRIVIA_ICON,
    'red',
    '863', {
        slug: "trivia",
        items: [{
            color: "#e21737",
            answer: 2,
            options: ['פרעה', 'המן', 'מרדכי', 'ויזתא'],
            question: 'מי לא מופיע בסיפור המגילה?'
        }, {
            color: "#e21737",
            answer: 3,
            options: ['פרסית', 'עברית', 'יידיש', 'ארמית'],
            question: 'מה מקור המילה "פור" (גורל)?'
        }, {
            color: "#e21737",
            answer: 0,
            options: ['הדסה', 'ושתי', 'כוכבה', 'מלכה'],
            question: 'אסתר המלכה ידועה גם בשם… ?'
        }, {
            color: "#e21737",
            answer: 1,
            options: ['סורסתא', 'פַּרְמַשְׁתָּא', 'אֲדַלְיָא', 'פּוֹרָתָא'],
            question: 'מי אינו בנו של המן הרשע?'
        }, {
            color: "#e21737",
            answer: 1,
            options: ['לחלק מתנות לאביונים', 'להחליף משלוחי מנות', 'לשחק ברעשן', 'כל התשובות נכונות'],
            question: 'בחג פורים נהוג...'
        }, {
            color: "#e21737",
            answer: 1,
            options: ['לחלק מתנות לאביונים', 'להחליף משלוחי מנות', 'לשחק ברעשן', 'כל התשובות נכונות'],
            question: 'איך נקראת התהלוכה המסורתית של חג פורים?',
            image: TRIVIA_ICON
        }]
    }, '111', ToolTrivia);

export default TRIVIA;