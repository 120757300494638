import React from 'react';
import { Link } from 'react-router-dom';
import { VBox, HBox, Element } from 'react-stylesheet';
import { MdLanguage, MdBackspace } from 'react-icons/md';


import { gutters, font_size, colors } from '../../00_base/variables';
import { Container } from 'react-grid-system';
import { Circle, Span, Button } from '../../01_atoms';



class ToolPassword extends React.Component {
    constructor(props) {
        super(props);

        const { password, keyboardType } = this.props;
        console.log('password', password)
        this.state = {
            isValid: -1,
            userInput: '',
            password: password,
            secret: 0,
            // targetTool: location.target,
            // colors: themes[0],
            lettersType: keyboardType ? keyboardType : 0,
        };

        this.numberClick = this.numberClick.bind(this);
        this.clearNumberInput = this.clearNumberInput.bind(this);
        this.submitNumberInput = this.submitNumberInput.bind(this);
        this.switchType = this.switchType.bind(this);
        this.backspace = this.backspace.bind(this);
    }

    secretHandler = () => {
        this.setState({
            secret: this.state.secret + 1,
            userInput: this.state.secret > 20 ? this.state.password : this.state.userInput
        })

        setTimeout(() => {
            this.setState({
                secret: 0
            })
        }, 8000)
    }
    numberClick(num) {
        const number = this.state.userInput += num;
        this.setState({
            userInput: number,
            isValid: 1,
            secret: 0
        });
    }

    backspace() {
        const { userInput } = this.state;
        const newNumber = userInput.substring(0, userInput.length - 1);
        this.setState({
            userInput: newNumber,
            isValid: 1
        });
    }

    clearNumberInput() {
        this.setState({
            userInput: '',
            isValid: 1
        })
    }

    submitNumberInput() {
        const { userInput, password } = this.state;
        if (userInput === password) {

            this.setState({
                isValid: 1
            });

            this.props.handleSuccess();

        } else {
            this.setState({
                isValid: 0
            });
            // localStorage.isContactPasswordConfirm = false
        }
    }

    switchType() {
        const { lettersType } = this.state;
        this.setState({
            lettersType: lettersType === 0 ? 1 : lettersType === 1 ? 2 : lettersType === 2 ? 0 : 0
        })
    }

    render() {
        const { isValid, userInput, password, lettersType } = this.state;
        return (
            <Element   style={{ flex: 1, background: '#136a8a', background: '-webkit-linear-gradient(to right, #136a8a, #267871)', background: 'linear-gradient(to right, #136a8a, #267871)' }}>

                <Container style={{

                    display: 'flex',
                    alignItems: 'space-between',
                    position: 'relative',
                   
                    // zIndex: '100',
                    // opacity: !password && '0'
                }}>
                    <VBox justifyContent='center'
                        height={'100%'}
                        style={phoneWrapper}>

                        <Element width={'100%'} marginBottom={gutters.md} marginTop={gutters.md}>
                            <HBox alignItems={'center'} justifyContent={'center'} style={inputField}>
                                {userInput}
                            </HBox>
                        </Element>


                        {lettersType === 0 &&
                            <Numbers typing={this.numberClick} colors={{ buttonBackground: colors.white, color: colors.black }} />
                        }

                        {lettersType === 1 &&
                            <Letters typing={this.numberClick} colors={{ buttonBackground: colors.white, color: colors.black }} lang={'en'} />
                        }

                        {lettersType === 2 &&
                            <Letters typing={this.numberClick} colors={{ buttonBackground: colors.white, color: colors.black }} lang={'he'} />
                        }

                        <Element style={{ ...wrongPassword, opacity: isValid === 0 ? '1' : '0' }} onClick={this.secretHandler}>
                            {'סיסמא שגויה'}
                        </Element>

                        <HBox width={'100%'} justifyContent={'center'} alignItems={'center'}>
                            <MdBackspace
                                color={colors.white}
                                size={30}
                                onClick={this.backspace} />


                            {/*<button onClick={this.clearNumberInput}*/}
                            {/*style={{*/}
                            {/*...button,*/}
                            {/*...btnClear,*/}
                            {/*backgroundColor: colors.fifth,*/}
                            {/*color: colors.buttons*/}
                            {/*}}>מחק*/}
                            {/*</button>*/}

                            <Element margin={`0 ${gutters.lg}`}>
                                {userInput === password || userInput === '' ?
                                    <button onClick={this.submitNumberInput}
                                        style={{
                                            outline: '0',
                                            ...button,
                                            ...btnSubmit,
                                            color: colors.buttonText,
                                            padding: 0,
                                            backgroundColor: 'transparent',

                                        }}>
                                        <Circle size={65}
                                            background={userInput === '' ? '#d67c7c' : '#9de09b'} />
                                    </button>
                                    :
                                    <button onClick={this.submitNumberInput}
                                        style={{
                                            ...button,
                                            ...btnSubmit,
                                            backgroundColor: 'transparent',
                                            padding: 0,
                                            outline: 0
                                        }}>
                                        <Circle size={65}
                                            background={userInput === '' ? '#d67c7c' : '#9de09b'} />
                                    </button>
                                }
                            </Element>

                            <MdLanguage
                                color={colors.white}
                                size={30}
                                // onClick={this.switchType}
                                 />
                        </HBox>
                    </VBox>
                </Container>
            </Element>
        )
    }
}


class Numbers extends React.Component {
    render() {
        const { typing, colors } = this.props;
        const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];
        return (
            <HBox flexDirection='row-reverse'
                flexWrap='wrap'
                justifyContent='center'
                style={buttons}>

                {numbers.map((num) => {
                    return (
                        <div key={num}
                            style={{ ...number }}
                            onClick={() => typing(num)}>
                            <Circle background={this.props.colors.buttonBackground} size={60}
                                fontSize={'2.5rem'}>
                                <Span color={this.props.colors.color}>{num}</Span>
                            </Circle>
                        </div>
                    )
                })
                }
            </HBox>
        )
    }
}

class Letters extends React.Component {
    render() {
        const { typing, lang } = this.props;
        const letters_en = [
            ['P', 'O', 'I', 'U', 'Y', 'T', 'R', 'E', 'W', 'Q', '*'],
            ['L', 'K', 'J', 'H', 'G', 'F', 'D', 'S', 'A'],
            ['M', 'N', 'B', 'V', 'C', 'X', 'Z'],
            [',', '.', '(', ')', '-', '&', '@']
        ];

        const letters_he = [
            ['/', 'פ', 'ם', 'ן', 'ו', 'ט', 'א', 'ר', 'ק', '*'],
            ['ף', 'ך', 'ל', 'ח', 'י', 'ע', 'כ', 'ג', 'ד', 'ש'],
            ['ץ', 'ת', 'צ', 'מ', 'נ', 'ה', 'ב', 'ס', 'ז'],
            [',', '.', '(', ')', '-', '&', '@']
        ];


        const letters = lang === 'he' ? letters_he : letters_en;

        return (
            <HBox flexDirection='row-reverse'
                flexWrap='wrap'
                justifyContent='space-between'
                style={buttons}>
                {letters.map((row, r) => {
                    return (
                        <HBox flexWrap={'nowrap'} justifyContent={'space-around'} width={'100%'} key={r}>
                            {row.map((letter, l) => {
                                return (
                                    <div key={letter}
                                        style={{
                                            ...letterStyle,
                                            color: this.props.colors.color,
                                            backgroundColor: colors.white,
                                            maxWidth: r === 0 ? 'calc(10% - 10px)' : r === 1 ? 'calc(11.111% - 10px)' : 'calc(14.285% - 10px)',
                                            fontSize: font_size.lg,
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            flex: 1,
                                            width: '100%'
                                        }}
                                        onClick={() => typing(letter)}>
                                        {letter}
                                    </div>
                                )
                            })}
                        </HBox>
                    )

                })}
            </HBox>
        )
    }

    static defaultProps = {
        lang: 'en'
    }
}




const inputField = {
    width: '100%',
    height: '3rem',
    fontSize: font_size.xl,
    textAlign: 'center',
    borderTop: '0',
    borderLeft: '0',
    borderRight: '0',
    backgroundColor: 'transparent',
    color: colors.white,
    fontWeight: 'bold'
},
    phoneWrapper = {
        width: '100%',
        maxWidth: '500px',
        margin: '0 auto',
        height: '100%',
        borderRadius: '2rem',
        // padding: '1.5rem',
    },
    submitButton = {
        width: '100%',
        overflow: 'visible',
        borderRadius: '2rem',
        color: '#000',
        height: '4rem'
    },
    buttons = {},
    number = {
        width: '30%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '.5rem',
        padding: `${gutters.xs} 0`,
        fontWeight: 'bold',
        color: colors.black,
        fontSize: font_size.md
    },
    letterStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // borderRadius: '.5rem',
        // margin: `${gutters.sm} 0 ${gutters.sm}`,
        padding: gutters.sm,
        // color: '#fff',
        backgroundColor: '#fff',
        font_size: '3rem',
        width: '100%'
    },
    wrongPassword = {
        fontSize: '2.5rem',
        color: '#d67c7c',
        textAlign: 'center',
        paddingBottom: '1rem',
        fontWeight: 'bold'
    },
    button = {
        border: '0',
        fontSize: '2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    btnSubmit = {
        border: '0',
        color: '#000',
        fontWeight: 'bold',
        overflow: 'hidden',
        textAlign: 'center'
    },
    btnClear = {
        fontWeight: 'bold',
        border: '0',
        overflow: 'hidden'
    };
export default ToolPassword;
