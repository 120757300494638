import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors, gutters } from '../../../00_base/variables';

const CardFooter = props => {
    return (
        <Footer className={'card-footer'} {...props}  {...props.style}>{props.children}</Footer>
    )
}

const styles = {
    wrapper: {
        backgroundColor: 'transparent',
        borderBottom: `1px solid ${colors.border}`,
        padding: `${gutters.xs} ${gutters.sm}`,
        
    }
};

const Footer = styled.footer`
    ${props => `
        background-color: ${props.backgroundColor};
        border-bottom: ${props.borderBottom};
        padding: ${props.padding};
        display: ${props.display};
        justify-content: ${props.justifyContent};
        align-items: ${props.alignItems};
    `}
`;

CardFooter.defaultProps = {
    style: styles.wrapper,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}

CardFooter.propTypes = {
    style: PropTypes.object,
    display: PropTypes.oneOf(['block', 'flex', 'none']),
    justifyContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around']),
    alignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center'])
};

export default CardFooter;