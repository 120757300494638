// import Too from "../../_models/project-model";
// import { mockProjects } from "../../_mocks/projects";
import { mockTools } from "../../mocks/tools";
import Tool from "../../_models/tool-model";
import ToolTifzoret from "../../../components/tools/tifzoret/";
import ToolGallery from "../../../components/tools/gallery/";
import ToolContactList from "../../../components/tools/contact-list/";
import ToolTrivia from "../../../components/tools/trivia/";
import ToolVideo from "../../../components/tools/viedo";
import ToolTranslator from "../../../components/tools/translator/";
import ToolTreasure from "../../../components/tools/treasure/";
import ToolImage from "../../../components/tools/image";

const api = 'https://api.youdoadventures.com';
const extenstion = 'adventures';
const adventureId = 1821;
const url = `${api}/${extenstion}/${adventureId}`;

const tools = [
    { id: 8, component: ToolContactList },
    { id: 6, component: ToolGallery }, 
    { id: 7, component: ToolImage },
    { id: 10, component: ToolVideo },
    { id: 14, component: ToolTrivia },
    { id: 18, component: ToolTifzoret }, 
    { id: 20, component: ToolTranslator },
    { id: 100, component: ToolTreasure },
]

export const SET_TOOLS = 'SET_TOOLS';
// export const CREATE_PROJECT = 'CREATE_PROJECT';
// export const UPDATE_PROJECT = 'UPDATE_PROJECT';
// export const DELETE_PROJECT = 'DELETE_PROJECT';

export const getAllTools = props => {
    return async (dispatch, getState) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Something went wrong');
            }
            const responseData = await response.json();

            const riddles = responseData.json_data.riddles;
            console.log('riddles', responseData.json_data);
            const treasureData = responseData.json_data.treasure;
            const treasure = new Tool(
                100,
                'האוצר',
                '',
                treasureData.icon,
                '#000',
                treasureData.password,
                {final_video: responseData.json_data},
                treasureData.description,
                ToolTreasure,
             );
            
            let allTools = [];
            for (const key in riddles) {
                const toolData = riddles[key].tool_data;
                const toolId = riddles[key].tool_id;
                const toolAnswer = riddles[key].answer;
                const cluesData = riddles[key].clues_data
                
                if (!!toolData) {
                    const toolComponent = tools.find(tool => tool.id === toolId)
                    console.log(toolComponent);
                    
                    allTools.push(
                        new Tool(
                            toolData.title,
                            toolData.title,
                            toolData.description,
                            toolData.thumbnail,
                            '',
                            toolData.password,
                            {
                                ...toolData
                            },
                            toolAnswer,
                            toolComponent && toolComponent.component ? toolComponent.component : toolComponent,
                            cluesData
                        )
                    )
                }
            }

            allTools.push(treasure);


            dispatch({
                type: SET_TOOLS,
                allTools: allTools
            })
            return allTools

        } catch (error) {
            throw error
        }
    }
}

// export const createProject = (title, name, description, images, website_url) => {
//     return async (dispatch, getState) => {
//         fetch(url, {
//             method: 'POST',
//             body: JSON.stringify({ title, name, description, images, website_url }),
//             headers: {
//                 'Content-Type': 'application/json;  charset=utf-8',
//                 'access-Token': ''
//             }
//         }).then(
//             response => {
//                 if (response.status >= 200 && response.status < 300) {
//                     response.json()
//                 } else {
//                     let error = new Error(response.statusText);
//                     error.response = response;
//                     throw error
//                 }
//             }
//         ).then(
//             success => {
//                 dispatch({
//                     type: CREATE_PROJECT,
//                     id: success.data,
//                     title,
//                     name,
//                     description,
//                     images,
//                     website_url
//                 })
//             }
//         )
//     }
// };

// export const updateProject = (id, title, name, description, images, website_url) => {
//     return async (dispatch, getState) => {
//         fetch(`${url}/${id}`, {
//             method: 'PUT',
//             body: JSON.stringify({ id, title, name, description, images, website_url }),
//             headers: {
//                 "Content-Type": "application/json; charset=utf-8",
//                 'access-Token': ''
//             }
//         }).then(
//             response => {
//                 if (response.status >= 200 && response.status < 300) {
//                     return response
//                 } else {
//                     let error = new Error(response.statusText);
//                     error.response = response;
//                     throw error
//                 }
//             }
//         ).then(
//             success => {
//                 dispatch({
//                     type: UPDATE_PROJECT,
//                     id, title, name, description, images, website_url
//                 });
//             }
//         );
//     };
// };

// export const deleteProject = projectId => {
//     return async (dispatch, getState) => {
//         fetch(`${url}/${projectId}`, {
//             method: 'DELETE', 
//             headers: {
//                 "Content-Type": "application/json; charset=utf-8",
//                 'access-token': ''
//             }
//         }).then(
//             response => response
//         ).then(
//             success => dispatch({ type: DELETE_PROJECT, id: projectId })
//         ).then(
//             error => console.log('error', error)
//         );
//     }
// }
