import { SET_TOOLS } from "../actions/tools/tools-actions";

import Tool from "../_models/tool-model";

const initialState = {
    allTools: []
};

const toolsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TOOLS:
            return {
                allTools: action.allTools
            };
/*
        case CREATE_PROJECT:
            const newItem = new Project(
                action.id,
                action.title, 
                action.name,
                action.description,
                action.images,
                action.website_url,
                action.placeholder
            );

            return {
                ...state,
                allTools: state.allTools.concat([newItem])
            }

        case UPDATE_PROJECT:
            const itemIndex = state.allTools.findIndex(item => item.id === action.locationId);
            const updatedItem = new Project(
                action.id,
                action.title,
                action.name,
                action.description,
                action.images,
                action.website_url,
                action.placeholder
            );

            const allProjects = state.allProjects;
            allProjects[itemIndex] = updatedItem;

            return {
                ...state,
                allProjects: allProjects
            }

        case DELETE_PROJECT:
            return {
                ...state,
                allProjects: state.allProjects.filter(
                    project => project.id !== action.projectId
                ),
            };
  */          
        default:
            return state;
    }
};

export default toolsReducer;