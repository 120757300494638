import Tool from "../../_models/tool-model";
import PEOPLE from '../../../assets/images/people.jpg'
import GLASS from '../../../assets/images/digital-tools-icons/glass.png';
import ToolTifzoret from "../../../components/tools/tifzoret/tool-tifzoret";

const TIFZORET = new Tool(
    18,
    'Tifzoret',
    'Tifzoret descrpition',
    GLASS,
    'blue',
    '135', {
        number_code: '1234',
        correctItems: [PEOPLE, PEOPLE, PEOPLE, PEOPLE, PEOPLE, PEOPLE, PEOPLE, PEOPLE, PEOPLE, PEOPLE, PEOPLE, PEOPLE, PEOPLE, PEOPLE, PEOPLE],
        wrongItems: [GLASS, GLASS, GLASS, GLASS, GLASS, GLASS, GLASS, GLASS, GLASS, GLASS, GLASS, GLASS, GLASS, GLASS, GLASS, GLASS, GLASS, GLASS, GLASS, GLASS, GLASS, ],
    },
    '4112',
    ToolTifzoret
);

export default TIFZORET;