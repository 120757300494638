import Tool from "../../_models/tool-model";


import PEOPLE from '../../../assets/images/people.jpg'

import GLASS from '../../../assets/images/digital-tools-icons/glass.png';
import ToolContactList from "../../../components/tools/contact-list/tool-contact-list";

const CONTACT_LIST = new Tool(
    4,
    'Contact List',
    'Contact List descrpition',
    GLASS,
    'blue',
    '843', {
        slug: 'contact-list',
        items: [
            { name: 'דפי', description: 'נהוג לחגוג את פורים ב- י"ד באדר, אך בערים שהיו מוקפות חומה כמו ירושלים, בתאריך ט"ו באדר. החג ביום השני נקרא שושן פורים.', audio: '', background: '', color: 'red', icon: PEOPLE, },
            { name: 'יאיר', description: 'אוזן המן נקרא על שמו של המן הרשע. מדובר על מאפה משולש בעל שלוש קרנות, ומקורו במרכז אירופה.', audio: '', background: '', color: 'red', icon: PEOPLE, },
            { name: 'יעל', description: 'ידעתם שהחיפוש אחר המלכה החדשה שתמלא את מקומה של ושתי, נמשך 4 שנים?', audio: '', background: '', color: 'red', icon: PEOPLE, },
            { name: 'רותם', description: 'מרדכי היה הראשון שנקרא "יהודי". קודם לכן נהגו לכנות את  בני עמנו בשם "עברים" או "ישראלים"...', audio: '', background: '', color: 'red', icon: PEOPLE, },
            { name: 'חנוך', description: 'משלוח מנות, מתנות לאביונים, מקרא מגילה, משתה ושמחה הם 4 המצוות הנהוגות בפורים', audio: '', background: '', color: 'red', icon: PEOPLE, },
            { name: 'תמי', description: 'עדלאידע היא תהלוכה צבעונית הנערכת בחג פורים. מסורת העדלאידע התחילה  בתל אביב, 36 שנה לפני קום המדינה', audio: '', background: '', color: 'red', icon: PEOPLE, },
        ]
    },
    '111',
    ToolContactList
);

export default CONTACT_LIST;