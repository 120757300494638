import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { mockProjects } from '../../../store/_mocks/projects';

import { Element } from 'react-stylesheet';
import styled from 'styled-components';
import { Block, Navigation, Grid } from '../../03_organisms';
import { Row, Col } from 'react-grid-system';
import { HBox } from 'react-stylesheet/lib/Box';
import { font_size, gutters, colors } from '../../00_base/variables';
import { Card } from '../../02_molecules';
import Feed from '../../03_organisms/feed/feed';
import AnimateSlide from '../../00_base/animations/animate-slide';



const Half = ({ children, backgroundColor, direction, speed, delay, starterPoint }) => {
    const halfStyle = {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: backgroundColor
    }
    return (
        <AnimateSlide
            style={halfStyle}
            starterPoint={starterPoint}
            dir={direction}
            speed={speed}
            delay={delay}>
            {children}
        </AnimateSlide>
    )
}



const HiddenPanel = ({ navigationItems, animationSpeed }) => {
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [allProjects, setAllProjects] = useState(useSelector(state => state.projects.allProjects));

    const dispatch = useDispatch();

    const renderFeedCard = (item) => {
        return (
            <Card cardType={3}
                title={item.title}
                description={item.description.slice(0, 40)}
                image={item.images[0]} />)
    };


    useEffect(() => {
        // try {
        //     dispatch(projectsActions.getAllProjects()).then(response => {
        //         setAllProjects(mockProjects);
        //         setIsLoading(false);
        //     })
        // } catch (error) {
        //     setAllProjects(mockProjects);
        //     setIsLoading(false);
        //     setError(error.message);
        //     setIsLoading(false);
        // }
    }, [dispatch]);

    return (

        <Block height={'100%'} alignItems={'stretch'}>
            <Row style={{ height: '100%' }} gutterWidth={0}>
                <Col style={{ height: '100%' }}>
                    <Half backgroundColor={colors.gray_1} delay={animationSpeed} speed={animationSpeed} direction={'tb'} starterPoint={'-100%'} after={{ background: 'red' }}>
                        <Navigation items={navigationItems} dir={'column'} itemStyle={{ fontSize: font_size.xxxl }} />
                    </Half>
                </Col>
                <Col>
                    <Half backgroundColor={colors.gray_10} delay={animationSpeed.replace('s', '') * 2 + 's'} speed={animationSpeed} direction={'bt'} starterPoint={'100%'}>
                        <Feed spaceBetweenItems={'20px'}
                            padding={`0 ${gutters.lg}`}
                            items={allProjects.slice(0, 3).map(renderFeedCard)} />
                    </Half>
                </Col>
            </Row>
        </Block>

    )
}

export default HiddenPanel;