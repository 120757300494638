import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors, gutters } from '../../../00_base/variables';
import { padding } from 'react-stylesheet/lib/css';

const CardBody = ({ children, style, height }) => {
    return (
        <Body className={'card-body'} {...style} height={height}>{children}</Body>
    )
}

const styles = {
    wrapper: {
        backgroundColor: colors.gray_2,
        padding: 0
    }
};

const Body = styled.div`
    position: relative;
    ${props => `
        height: ${props.height};
        overflow: hidden;
        background-color: ${props.backgroundColor};
        padding: ${props.padding};
    `}
`;

CardBody.defaultProps = {
    style: styles.wrapper
}

CardBody.propTypes = {
    style: PropTypes.object
};

export default CardBody;