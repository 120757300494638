import React, { useState, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Element, VBox, HBox } from 'react-stylesheet';
import { Page } from '../../04_layout';
import * as toolsActions from '../../../store/actions/tools/tools-actions';
import { Heading, Image, Button } from '../../01_atoms';
import { font_size, gutters, colors } from '../../00_base/variables';
import { MdDashboard } from 'react-icons/md';
import Timer from '../../03_organisms/timer/timer';
import ToolPassword from '../../tools/password';
import SimpleLoader from '../../01_atoms/loaders/simple-loader';
import ScreenLoading from '../loading/screen-loading';
import ToolClues from './tool-clues';


const CONFIRM_PASSWORD = 'CONFIRM_PASSWORD';
const passwordReducer = (state, action) => {
    if (action.type === CONFIRM_PASSWORD) {
        const toolId = action.toolId;
        const updated = [...state.tools, toolId];
        localStorage.setItem('confirmedPassword', JSON.stringify(updated));
        return {
            tools: updated
        }
    }

    return state;
};

const ScreenTool = ({ match, location }) => {
    const hideClues = localStorage.getItem('hideClues');

    const toolId = match.params.id === '100' ? 100 : match.params.id;
    const [tool, setToolData] = useState(location.toolData);
    const [confirmedPassword, setConfirmedPassword] = useReducer(passwordReducer, { tools: JSON.parse(localStorage.getItem('confirmedPassword')) });
    const [passwordActive, setPasswordActive] = useState(!!tool && !!tool.password && !confirmedPassword.tools.some(toolId => toolId === tool.id));
    const [isLoading, setIsLoading] = useState(!tool);
    const [error, setError] = useState(null);
    const [clueActive, setClueActive] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!tool) {
            try {
                dispatch(toolsActions.getAllTools()).then(response => {
                    const responseData = toolId === 100 ? response.find(tool => tool.id === toolId) : response.find(tool => tool.title === toolId);
                    setToolData(responseData);
                    setPasswordActive(!!responseData && !!responseData.password);
                    setIsLoading(false);
                })
            } catch (error) {
                setError(error.message);
                setIsLoading(false);
            }
        }
    }, [dispatch]);

    const ToolTopBar = ({ cluesData }) => {
        return (
            <HBox background={colors.white}
                justifyContent={'space-between'}
                alignItems={'center'}
                padding={`${gutters.sm} ${gutters.sm}`}>
                <HBox justifyContent={'flex-start'} style={{ flex: 1 }}>
                    <Timer color={colors.black} />
                </HBox>
                <Heading
                    tag={'h1'}
                    color={colors.black}
                    fontSize={font_size.sm}
                    textAlign={'center'}
                    style={{ margin: 0 }}>
                    {tool.title === 'האוצר' ? 'נטרול הוירוס' : tool.title}
                </Heading>
                <HBox justifyContent={'flex-end'} alignItems={'center'} style={{ flex: 1 }}>

                    {hideClues !== "true" && !!cluesData && !(passwordActive && showPassword()) &&
                        <Button
                            backgroundColor={colors.secondary}
                            onClick={() => setClueActive(true)}
                            radius={'10px'}
                            style={{ marginLeft: gutters.sm, backgroundColor: colors.secondary }}>
                            רמז
                        </Button>}

                    <Link to={'/menu'}>
                        <MdDashboard size={20} color={colors.black} />
                    </Link>
                </HBox>
            </HBox>
        )
    }

    const confirmPasswordHandler = () => {
        setPasswordActive(false);
        setConfirmedPassword({
            type: CONFIRM_PASSWORD,
            toolId: tool.id
        });
    };

    const showPassword = () => {
        return (!JSON.parse(localStorage.getItem('confirmedPassword')).some(toolId => toolId === tool.id)) &&
            !!tool.password &&
            !confirmedPassword.tools.some(toolId => toolId === tool.id)
    }

    if (isLoading) return <ScreenLoading />

    const TifzoretClue = ({ isActive }) => {
        return (
            <HBox
                width={'100%'}
                height={'100%'}
                background={'#136a8a'}
                justifyContent={'center'}
                alignItems={'center'}
                position={'absolute'}
                zIndex={1}
                top={0}
                left={0}>
                <Button
                    radius={'10px'}
                    onClick={() => setClueActive(false)}
                    style={{ position: 'absolute', top: '10px', left: '10px', backgroundColor: colors.secondary }}
                >חזור</Button>
                <Heading color={'#fff'} fontSize={font_size.jumbo}> י"א באדר</Heading>
            </HBox>
        )
    }

    return (
        <Page>
            <VBox minHeight={'100%'} width={'100%'} overflow={'auto'}>

                <ToolTopBar cluesData={!!tool && tool.cluesData} />

                {!!tool.cluesData &&
                    <ToolClues
                        clueIndex={0}
                        cluesData={tool.cluesData}
                        close={(callback) => {
                            setClueActive(false);
                            setTimeout(() => {
                                callback();
                            }, 1000)
                            
                        }}
                        isActive={clueActive} />}


                {passwordActive && showPassword() ?
                    <ToolPassword
                        keyboardType={tool.id === 18 ? 2 : 0}
                        password={tool.password}
                        handleSuccess={confirmPasswordHandler} /> :
                    <tool.component
                        answer={tool.answer}
                        password={tool.password}
                        color={tool.color}
                        src={tool.toolData.image}
                        {...tool.toolData} />}
            </VBox>
        </Page>
    )
};

export default ScreenTool;