import React from 'react';
import { Page } from '../../04_layout';
import { VBox } from 'react-stylesheet/lib/Box';
import SimpleLoader from '../../01_atoms/loaders/simple-loader';
import { Image } from '../../01_atoms';
import LOGO from '../../../assets/images/logos/youdo_logo.png';

const ScreenLoading = () => {
    return (
        <Page>
                <VBox height={'100%'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                    <SimpleLoader size={60}/>
                    <Image src={LOGO} width={250}/>
                </VBox>
            </Page>
    )
}

export default ScreenLoading;