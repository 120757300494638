import React from 'react';
import Heading from '../../../01_atoms/text/a-heading';
import PropTypes from "prop-types";
import { colors, font_size } from '../../../00_base/variables';

const PageTitle = props => {
    return (
        <Heading {...props} textDecoration={props.underiline && 'underiline'} />
    )
};

PageTitle.defaultProps = {
    color: colors.black,
    fontSize: font_size.pageTitle,
    textAlign: 'center',
    textDecoration: 'none'
};

export default PageTitle;