import React from 'react';
import {HBox, VBox, Element} from 'react-stylesheet';
import {Container} from 'react-grid-system';
import {font_size, gutters, colors, zIndex} from '../../00_base/variables';
import Iframe from '../../01_atoms/iframe';
import {Button, Image, Heading} from '../../01_atoms';
import YOOUDOLOGO from '../../../assets/images/logos/youdo_logo.png';
import BOSKO_LOGO from '../../../assets/images/logos/cerebro-logo.png';
import {Form, Fireworks} from '../../03_organisms'
import styled from 'styled-components';
import {MdCameraAlt} from 'react-icons/md';

class ToolTreasure extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: 'נטרול הוירוס',
            showTreasureScreen: !!this.props.answer,
            treasureValue: '',
            showValidationError: false,
            isLoading: true
        };
        this.handleTreasureSubmit = this.handleTreasureSubmit.bind(this);
        this.showFinalScreen = this.showFinalScreen.bind(this);
        this.treasureChange = this.treasureChange.bind(this);
    }

    componentDidMount() {
        this.handleTreasureSubmit();
    }

    handleTreasureSubmit(formData) {
        const treasureValue = this.props.answer;
        if (this.props.answer === treasureValue) {
            this.setState({
                successTreasure: true,
                isLoading: false
            })


        } else {
            this.setState({
                showValidationError: true
            })
        }
    }

    treasureChange(e) {
        this.setState({
            treasureValue: e.target.value
        })
    }

    showFinalScreen() {
        this.setState({
            showFinalScreen: true
        })
    }

    handleShareFoemSubmit = (formData) => {
        this.setState({
            selfieSuccess: true
        });
        const data = {
            adventure_id: 1821,
            name: formData.name,
            text: formData.description,
            title: formData.title,
            images: [formData.selfie]
        };
        this.postData(
            'reviews',
            data,
            (success) => this.handleSuccess(success)
        )
        console.log("handleShareFoemSubmit, m", formData);
    }

    postData = (type, data, handleSuccess, handleError) => {
        this.setState({
            selfieSuccess: 'loading'
        })
        fetch(`${'https://api.youdoadventures.com'}/${type}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'access-Token': localStorage.token && localStorage.token
            }
        }).then(
            response => response.status >= 200 && response.status < 300 && response.json()
        ).then(
            success => this.setState({
                selfieSuccess: true
            })
        ).then(
            error => handleError ? handleError(error) : console.log(error)
        );


        function checkStatus(response) {
            if (response.status >= 200 && response.status < 300) {
                return response
            } else {
                handleError('error')
                // let error = new Error(response.statusText);
                // error.response = response;
                // throw error
            }
        }
    }

    render() {
        const {showTreasureScreen, treasureValue, showValidationError, selfie_cover, finalVideoUrl} = this.state,
            {finalScreenBackground} = this.props;
        const backgroundImage = finalScreenBackground ? finalScreenBackground : null;

        const treasureField = [{
            type: 'text',
            name: 'treasureValue',
            color: colors.gray_10,
            fontSize: font_size.lg,
            textAlign: 'center',
            paddingVertical: gutters.xs,
            color: colors.black

        }]

        const shareFormFields = [{
            type: 'file',
            name: 'selfie',
            label: 'העלו תמונת ניצחון ושתפו בחוויתכם מההרפתקה!',
            singleFile: true,
            hidePreview: false,
            previewWidth: '100%',
            previewHeight: '200px',
            buttonWidth: '100%',
            triggerSize: '50px',
            TriggerIcon: MdCameraAlt,
            value: this.state.selfieUrl,
            setIsFileLoading: (data) => console.log("loading", data),
            fileUploaded: (data) => {
                this.setState({
                    selfieUrl: data[0]
                })
            },
        }, {
            type: 'text',
            label: 'שם',
            name: 'name',
            color: colors.black
        }, {
            type: 'text',
            label: 'כותרת',
            name: 'title',
            color: colors.black
        }, {
            type: 'textarea',
            label: 'שתפו חוויה (עד 140 תוים)',
            name: 'description',
            color: colors.black
        }];


        return (
            <VBox className={'has-background-image background'}


                  style={{flex: 1, justifyContent: 'center'}}
                // beforeColor={!showTreasureScreen && 'rgba(255,255,255,.5)'}
            >

                {this.state.successTreasure &&
                <FireworksWrapper>
                    <Heading
                        tag={'h2'}
                        textAlign={'center'}
                        color={colors.white}>
                        כל הכבוד!
                    </Heading>
                    <Heading
                        tag={'h3'}
                        textAlign={'center'}
                        color={colors.white}>
                        פיצחתם את ההרפתקה!
                    </Heading>

                    <Iframe src={' https://www.youtube.com/watch?v=hTA88ha88lo'} width={'100%'} height={'280px'} style={{ width: '100%' }} />

                    <Button
                        onClick={() => this.setState({successTreasure: false, showTreasureScreen: false})}
                        variant={'secondary'}

                        style={{zIndex: zIndex.medium, fontSize: font_size.lg}}>
                        צלמו תמונת נצחון </Button>
                </FireworksWrapper>
                }
                <Container style={{
                    position: 'relative',
                    flex: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>


                    {showTreasureScreen ?
                        <Element display={'none'} justifyContent={'center'} alignItems={'center'} width={'100%'}
                                 height={'100%'}
                                 className={'px-5'}>

                            <Heading style={{display: 'none'}} tag={'h2'} color={colors.black} textAlign={'center'}>הכניסו
                                את תשובתכם</Heading>

                            {/* <Form
                                fields={treasureField}
                                submitLabel={'המשך'}
                                fieldsStyle={{ color: '#000', fontSize: font_size.lg }}
                                submitStyle={{ backgroundColor: colors.secondary }}
                                handleFormSubmit={this.handleTreasureSubmit} />
 */}

                            {!this.state.successTreasure &&
                            <p style={{
                                fontSize: '1.8rem',
                                color: colors.gray_10,
                                marginBottom: '0',
                                overflow: 'hidden',
                                height: showValidationError ? '25px' : '0',
                                transition: '.3s height'
                            }}>נסה שנית</p>
                            }
                        </Element>
                        :
                        <Element>
                            {finalVideoUrl && <div>
                                <Iframe src={finalVideoUrl}
                                        width={'100%'}
                                        height={'230px'}/>
                            </div>}

                            <VBox alignItems={'center'} marginTop={'1rem'}>

                                <Form
                                    fields={shareFormFields}
                                    setIsFileLoading={(data) => console.log('loadingdata', data)}
                                    submitLabel={!this.state.selfieSuccess ? 'שתפו בהיכל התהילה' : this.state.selfieSuccess === 'loading' ? '...' : 'תמונתכם נכנסה להיכל התהילה'}
                                    submitStyle={{backgroundColor: colors.secondary}}
                                    handleFormSubmit={this.handleShareFoemSubmit}/>

                                {/* <ShareForm adventure_id={this.state.adventureId}
                                        title={'העלו תמונת ניצחון ושתפו בחוויתכם מההרפתקה!'}
                                        selfie_cover={selfie_cover} /> */}

                                <Element paddingBottom={gutters.md} className={'px-3'}>

                                    <p style={{
                                        color: '#000',
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        fontSize: font_size.lg
                                    }}>
                                        {(localStorage.adventureId === '1028' || localStorage.adventureId === 1028) ? 'הצטרפו אלינו להרפתקאות נוספות!' : 'המשחק פותח על ידי חברות'}
                                    </p>


                                    <HBox justifyContent={'space-around'} alignItems={'center'}>
                                    <a href={'https://www.youdoadventures.com'} target={'_blank'}>
                                            <Image src={YOOUDOLOGO} width={130}/>
                                        </a>
                                        <a href={'https://cerebro.co.il'} target={'_blank'}>
                                            <Image
                                                src={BOSKO_LOGO}
                                                width={'130'}/>
                                        </a>
                                        
                                        
                                    </HBox>
                                </Element>
                            </VBox>
                        </Element>
                    }
                </Container>
            </VBox>
        )
    }
}


const FireworksWrapper = styled.div`
    background-color: rgba(0,0,0,0.7);
    position: absolute;
    text-align:center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
     display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
`;

export default ToolTreasure;