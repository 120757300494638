import React from "react";
import {Switch, Route, withRouter} from "react-router-dom";

import {TransitionGroup, CSSTransition} from "react-transition-group";
import Wrapper from './screen-transitions/transitions';

import ScreenHomepage from "../../05_screens/homepage";
import ScreenInstructions from "../../05_screens/instructions";
import ScreenMenu from "../../05_screens/menu";
import ScreenTool from "../../05_screens/tool";
import ScreenPhoneCall from "../../tools/contact-list/screen-phone-call";
 

const AppContent = ({location, styles}) => { 
    console.log('styles', styles)
    return (
        <Wrapper id={'app-routes'}>
            <TransitionGroup>
                <CSSTransition key={location.key} timeout={{enter: 300, exit: 300}} classNames={'slide'}>
                    <section className="route-section">
                        <Switch location={location}>
                            <Route exact path={'/'} component={ScreenHomepage}/>

                            <Route path={'/ver'} component={() => <ScreenHomepage hideClues={true}/>}/>
                            <Route path={'/instructions'} component={ScreenInstructions}/>
                            <Route path={'/menu'} component={ScreenMenu}/>
                            <Route path={'/tool/:id'} component={ScreenTool}/>
                            <Route path={'/phone-call'} component={ScreenPhoneCall}/>
                        </Switch>
                    </section>
                </CSSTransition>
            </TransitionGroup>
        </Wrapper>
    );
};

export default withRouter(AppContent); 