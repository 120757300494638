import React from 'react';
import Card from '../m-card';
import { CardHeader, CardBody } from '../card-layout/';
import CardFooter from '../card-layout/m-card-footer';
import { Heading, Paragraph, Image } from '../../../01_atoms';
import { HBox } from 'react-stylesheet';
import CardActions from '../card-layout/m-card-actions';


const Card1 = ({
    title,
    subtitle,
    description,
    image,
    actions,
    titleStyle,
    subtitleStyle,
    descriptionStyle,
    actionsStyle,
    actionItemStyle,
    bodyHeight
}) => {
    console.log('actions', actions);
    return (
        <>
            <CardHeader>
                {!!title && <Heading tag={'h4'} style={titleStyle} value={title}/>}
                {!!subtitle && <Heading tag={'h6'} style={subtitleStyle} value={subtitle}/>}
            </CardHeader>
            <CardBody height={bodyHeight}>
                <Image src={image} block />
            </CardBody>
            <CardFooter>
                {!!description && <Paragraph style={descriptionStyle} value={description}/>}
                {!!actions && <CardActions style={actionsStyle} itemStyle={actionItemStyle} items={actions}/>}
            </CardFooter>
        </>
    )
}

export default Card1;