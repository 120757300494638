import React from 'react';
import PropTypes from "prop-types";
import { Container, Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import { gutters, colors, zIndex } from '../../00_base/variables';
import { Block, Navigation } from '../../03_organisms';
import { AppLogo } from '../../02_molecules';

const AppFooter = props => {
    const { leftComponent, rightComponent, centerComponent } = props;
    const { justifyContent, alignItems } = props;
    const footerNavigation = [
        { id: 0, label: 'Facebook', href: 'https://www.facebook.com' },
        { id: 1, label: 'Linkedin', href: 'https://www.linkedin.com' },
        { id: 2, label: 'Github', href: 'https://www.github.com' },
        { id: 3, label: 'Codepen', href: 'https://www.codepen.com' },
    ];
    return (
        <Block backgroundColor={colors.gray_10} paddingTop={gutters.lg} className={`app-footer ${props.classsName}`}>
            <Container fluid={props.fluid}>
                <Row style={{ justifyContent, alignItems }}>
                    <Col>
                        <FooterItem style={{ ...props.itemWrapperStyle, ...props.leftWrapperStyle }}>
                            {leftComponent && leftComponent}
                        </FooterItem>
                    </Col>
                    <Col>
                        <FooterItem style={{ textAlign: 'center' }}>
                            <AppLogo width={'20px'} />
                            <Navigation items={footerNavigation} listStyle={{justifyContent: ' center'}}  />
                        </FooterItem>
                    </Col>
                    <Col>
                        <FooterItem style={{ ...props.itemWrapperStyle, ...props.rightWrapperStyle }}>
                            {rightComponent && rightComponent}
                        </FooterItem>
                    </Col>
                </Row>
            </Container>
        </Block>
    )
}

const Footer = styled.footer`
    right: 0;
     bottom: 0;
    left: 0;
    position: absolute;
    z-index: ${zIndex.hidden}
    ${props => `
        background-color: ${props.backgroundColor};
        padding: ${props.paddingVertical} ${props.paddingHorizontal};
        border-top: ${props.borderTop}; 
    `}
`;

const FooterItem = styled.div`

`;

AppFooter.defaultProps = {
    className: '',
    backgroundColor: colors.gray_2,
    paddingVertical: gutters.sm,
    paddingHorizontal: gutters.sm,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: `1px solid ${colors.black}`,
    fluid: false
};
AppFooter.propTypes = {
    fluid: PropTypes.bool,
    leftComponent: PropTypes.element,
    rightComponent: PropTypes.element,
    centerComponent: PropTypes.element,
    itemWrapperStyle: PropTypes.object,
    leftWrapperStyle: PropTypes.object,
    centerWrapperStyle: PropTypes.object,
    rightWrapperStyle: PropTypes.object,
    backgroundColor: PropTypes.string,
    paddingVertical: PropTypes.string,
    paddingHorizontal: PropTypes.string,
    justifyContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around']),
    alignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
    borderTop: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,

};

export default AppFooter;