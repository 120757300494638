import React from "react";

import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk'
import { BrowserRouter as Router } from "react-router-dom";
import { useScreenClass, setConfiguration, ScreenClassProvider } from 'react-grid-system';
import { font_size, colors, gutters } from "./components/00_base/variables";
import { AppLogo } from "./components/02_molecules";
import { Navigation, Modal } from "./components/03_organisms";
import { AppHeader, AppContent, AppFooter } from "./components/04_layout";
import { config, library } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { faBars, faUser, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons'

import {
    gutterWidth,
    breakpoints,
    containerWidths,
    gridColumns,
} from './components/00_base/configurations';
import { Icon } from "./components/01_atoms";


import toolsReducer from "./store/reducers/tools-reducers";
import HiddenPanel from "./components/custom/hidden-panel/hidden-panel";

config.autoAddCss = false
library.add(faBars, faUser, faTimes, faTwitter, faFacebook)

const rootReducer = combineReducers({
    tools: toolsReducer,
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));


// **** NOTICE: Don't forget to update the variables in ./src/assets/css/variables. **** //


setConfiguration({
    defaultScreenClass: 'xl',
    breakpoints: breakpoints,
    containerWidths: containerWidths,
    gutterWidth: gutterWidth, 
    gridColumns: gridColumns,
});


// library.add(fab, faCheckSquare, faCoffee)

const navigationItems = [
    { id: 0, label: 'home', href: '/', route: true },
    { id: 1, label: 'instructions', href: '/instructions', route: true },
    { id: 2, label: 'menu', href: '/menu', route: true },
    { id: 3, label: 'tool', href: '/tool', route: true },
];

const appLogo = (<AppLogo width={'20px'} />);

const mainNavigation = (
    <Navigation
        // hiddenNavigation={{ triggerIcon: <Icon icon={faBars} /> }}
        navActiveStyle={{ backgroundClor: 'red' }}
        items={navigationItems}
        itemStyle={{ fontSize: font_size.md, color: colors.gray_10, textTransform: 'uppercase' }}
    />
);

const burgerMenu = (
    <Modal
        closeTrigger={{
            style: { width: '40px', height: '40px', fontSize: '20px', color: colors.white },
            symbol: 'X'
        }}
        triggerValue={<Icon icon={faBars} />}
        triggerStyle={{ backgroundColor: 'transparent' }}
        animationSpeed={'.4s'}
        animationDirection={'bt'}
        content={<HiddenPanel animationSpeed={'.4s'} navigationItems={navigationItems} />}
        contentStyle={{
            backgroundColor: '#fff',
            borderRaduis: '20px',
            width: '90%',
            height: '90%'
        }} />
)

// const burgerMenu = (
//     <Navigation
//         hiddenNavigation={{ openIcon: faBars, closeIcon: faTimes }}
//         navWrapperStyle={{ backgroundClor: 'red' }}
//         navigationComponent={HiddenPanel}
//         items={navigationItems}
//         itemStyle={{ fontSize: font_size.md, color: colors.white, textTransform: 'uppercase' }} />
// );

function App() {
    return (
        <Provider store={store}>
            <Router>
                <div id={'app-wrapper'}>
                    {/* <AppHeader
                        fixed
                        fluid
                        paddingHorizontal={gutters.lg}
                        borderBottom={`.1rem solid ${colors.black}`}
                        backgroundColor={colors.white}
                        leftComponent={appLogo}
                        centerComponent={mainNavigation}
                        rightComponent={burgerMenu}
                    /> */}
                    <AppContent />
                    {/* <AppFooter
                        backgroundColor={'rgba(0,0,0,.9)'}
                        leftComponent={appLogo}
                        centerComponent={mainNavigation}
                        rightComponent={<p>right</p>} /> */}
                </div> 
            </Router>
        </Provider>
    )
};

export default App;