import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'react-grid-system';
import { Link } from "react-router-dom";
import { gutters, colors } from '../../00_base/variables';
import { Image, Heading } from '../../01_atoms';
import { Page } from '../../04_layout';
import * as toolsActions from '../../../store/actions/tools/tools-actions';
import { mockTools } from '../../../store/mocks/tools';
import { Element, HBox } from 'react-stylesheet';
import MOBILE_BACKGROUND from '../../../assets/images/islam_background.jpg';
import ScreenLoading from '../loading/screen-loading';



const ScreenMenu = props => {
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [allTools, setAllTools] = useState(useSelector(state => state.tools.allTools));

    const dispatch = useDispatch();

    useEffect(() => {
        try {
            dispatch(toolsActions.getAllTools()).then(response => {
                setAllTools(response);
                console.log('response', response)
                setIsLoading(false);
            })
        } catch (error) {
            setError(error.message);
            setIsLoading(false);
        }
    }, [dispatch]);

    const renderTool = (tool, index) => {
        return (
            <Element key={index} padding={gutters.md} width={'33.33%'} textAlign={'center'}>
                <Link to={{ pathname: `/tool/${tool.id}`, toolData: tool }}>
                    <Image src={tool.icon} />
                    <Heading tag={'h6'} textAlign={'center'} color={colors.white} style={{ margin: 0 }}>{tool.title}</Heading>
                </Link>
            </Element>
        )
    }
    if (isLoading) return <ScreenLoading />

    return (
        <Page {...props} alignItems={'center'} style={{ background: `url(${MOBILE_BACKGROUND}) center center /cover` }}>
            <Container>
                <HBox flexWrap={'wrap'} width={'100%'} alignItems={'baseline'}>
                    {allTools.map(renderTool)}
                </HBox>
            </Container>
        </Page>
    )
};

export default ScreenMenu; 