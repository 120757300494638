import React, { useState, useEffect, useReducer, useCallback } from 'react';
import PropTypes from "prop-types";
import Button from '../../01_atoms/button/a-button';
import FieldText from '../../02_molecules/form-fields/text/m-field-text';
import FieldSelect from '../../02_molecules/form-fields/select/m-field-select';
import FieldRadio from '../../02_molecules/form-fields/radio/m-field-radio';
import FieldCheckbox from '../../02_molecules/form-fields/checkbox/m-field-checkbox';
import FieldTextArea from '../../02_molecules/form-fields/textarea/m-field-textarea';
import FileUploader from '../../02_molecules/form-fields/file-uploader/m-file-uploader';

const UPDATE_INPUT = 'UPDATE_INPUT';

const formReducer = (state, action) => {
    if (action.type === UPDATE_INPUT) {
        const updatedValues = {
            ...state.inputValues,
            [action.input]: action.value
        }
        const updateValiditeis = {
            ...state.inputValidities,
            [action.input]: action.isValid
        };
        let updatedFormIsValid = true;
        for (const key in updateValiditeis) {
            updatedFormIsValid = updatedFormIsValid && updateValiditeis[key]
        }

        return {
            inputValues: updatedValues,
            inputValidities: updateValiditeis,
            formIsValid: updatedFormIsValid,
        }
    }
    return state;
}

const initialValues = (fields) => {
    let fieldsData;
    for (const key in fields) {
        fieldsData = {
            ...fieldsData,
            [fields[key].name]: fields[key].value
        }
    }
    return fieldsData;
}

const initialValidities = (fields) => {
    let fieldsData;
    for (const key in fields) {
        fieldsData = {
            ...fieldsData,
            [fields[key].name]: !!fields[key].name
        }
    }
    return fieldsData;
}

const getFieldComponent = type => {
    switch (type) {
        case 'text': return FieldText;
        case 'select': return FieldSelect;
        case 'radio': return FieldRadio;
        case 'checkbox': return FieldCheckbox;
        case 'textarea': return FieldTextArea;
        case 'file': return FileUploader;
        default: return FieldText;
    }
}

const Form = ({ fields, handleFormSubmit, submitLabel, submitBlock, fieldsStyle, submitStyle }) => {
    const [isLoading, setIsLoading] = useState();
    const [error, setError] = useState();
    const [formState, dispatchFormState] = useReducer(formReducer, {
        inputValues: initialValues(fields),
        inputValidities: initialValidities(fields),
        formIsValid: !!fields
    });

    const dispatch = '';

    const inputChangedHandler = useCallback((name, value, isValid) => {
        dispatchFormState({
            type: UPDATE_INPUT,
            value: value,
            isValid: isValid,
            input: name
        });
    }, [dispatchFormState]);

    const formSubmitHandler = useCallback(async () => {

        if (!formState.formIsValid) {
            alert('Form is not valid. Please check your fields');
            return;
        }

        setError(null);
        setIsLoading(true);

        handleFormSubmit(
            formState.inputValues,
            handleSubmitSuccess,
            handleSubmitError
        );
    }, [dispatch, formState]);

    const handleSubmitSuccess = () => {
        setIsLoading(false);
    };

    const handleSubmitError = () => {
        setIsLoading(false);
        setError(true);
    }

    const renderField = (field, index) => {
        const Field = getFieldComponent(field.type);
        return (
            <Field
                {...field}
                fieldStyle={field.fieldStyle}
                key={index}
                changeHandler={inputChangedHandler}
                initialValue={formState.inputValues[field.name]}
                initiallyValid={formState.inputValidities[field.name]} />
        )
    };

    useEffect(() => {

    }, [isLoading]);

    return (
        <form onSubmit={(e) => { e.preventDefault(); formSubmitHandler(); }} style={{width: '100%'}}>
            
            {fields.map(renderField)}
            
            <Button value={submitLabel} block={submitBlock} style={submitStyle} />
        </form>
    )
}

Form.defaultProps = {
    submitLabel: 'submit',
    submitBlock: true
};

Form.propTypes = {
    submitLabel: PropTypes.string,
    submitBlock: PropTypes.bool,
    submitStyle: PropTypes.object,
    handleFormSubmit: PropTypes.func,
    fields: PropTypes.array.isRequired
};

export default Form;