import React, { useState, useEffect } from 'react';
import { HBox, Element } from 'react-stylesheet';
import PuzzleBoard from './puzzle-board'; 
import cover_1 from './frames/1.jpg';
import cover_2 from './frames/2.jpg';
import cover_3 from './frames/3.jpg';
import cover_4 from './frames/4.jpg';
import cover_5 from './frames/5.jpg'; 
import cover_6 from './frames/6.jpg';
import cover_7 from './frames/7.jpg';
import cover_8 from './frames/8.jpg';
import cover_9 from './frames/9.jpg';
import cover_10 from './frames/10.jpg';

import easy from '../../../assets/images/easy.png';
import medium from '../../../assets/images/medium.png';
import hard from '../../../assets/images/hard.png';

import { textAlign, translate3d } from 'react-stylesheet/lib/css';

// import ShareFacebook from '../../utils/share/share-facebook';


import { MdPlayCircleOutline, MdSettingsBackupRestore, MdHelp, MdHelpOutline, MdShare } from 'react-icons/md';
// import Fireworks from '../../utils/fireworks/fireworks';


const ToolPuzzle = ({ imageUrl, level, coverUrl, visitor, adventureId }) => {
    const [status, setStatus] = useState(visitor ? 'visitor' : !!level ? !!coverUrl ? 'ready' : 'chooseCover' : null);
    const [choosenLevel, setChoosenLevel] = useState(level);
    const [choosenCover, setChoosenCover] = useState(coverUrl);
    const [score, setScore] = useState(0);
    const [showNumbers, setShowNumbers] = useState(true);



    const ActionsBtns = ({ btns }) => {
        return <HBox justifyContent={'center'} padding={'20px 0'}>
            {btns.map(btn => {
                const Icon = btn.icon;
                return (
                    <Element
                        key={btn.id}
                        onClick={btn.action}
                        background={'transparent'}
                        // border={'2px solid #000'}
                        margin={'0 10px'}>
                        <Icon size={30} color={'#000'} />
                    </Element>
                )
            }
            )}
        </HBox>
    }

    const startGame = () => {
        setStatus('play');
        setChoosenLevel(choosenLevel)
    };

    if (!status) {
        const buttons = [
            // { label: 'Master', color: 'red', level: 5 },
            // { label: 'Very hard', color: 'orange', level: 4 },

            { label: 'Hard', color: '#f1162b', icon: hard, level: 3 },
            { label: 'Medium', color: 'pink', icon: medium, level: 2 },
            { label: 'Easy', color: 'lightblue', icon: easy, level: 1 }
        ];
        return (
            <div style={styles.wrapper} className='wrapper'>
                <h2 style={{ textAlign: 'center', fontWeight: 'bold', margin: '10px 0 20px' }}>Choose game level</h2>
                <div style={{ position: 'relative' }}>
                    {/* <img src={imageUrl} width='100%' /> */}

                    <div style={{ width: '100%', padding: '15px', backgroundImage: `url(${choosenCover})` }}>
                        <img src={imageUrl} width='100%' />
                    </div>

                    <div style={styles.levelButtonsWrapper}>
                        {buttons.map(button => {
                            return (
                                <div style={styles.levelButtonWrapper}>
                                    <button key={button.level} style={{ ...styles.levelButton, backgroundColor: button.color }}
                                        onClick={() => {
                                            setChoosenLevel(button.level);
                                            setStatus(coverUrl ? 'play' : 'chooseCover');
                                        }}>
                                        <img src={button.icon} width={'100%'} />
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }


    if (status === 'chooseCover') {
        const covers = [
            { id: 1, url: cover_1 },
            { id: 2, url: cover_2 },
            { id: 3, url: cover_3 },
            { id: 4, url: cover_4 },
            { id: 5, url: cover_5 },
            { id: 6, url: cover_6 },
            { id: 7, url: cover_7 },
            { id: 8, url: cover_8 },
            { id: 9, url: cover_9 },
            { id: 10, url: cover_10 },
        ];
        return (
            <div style={styles.wrapper}>
                <img src={imageUrl} width='100%' />
                <h4>Choose Board</h4>
                <div style={styles.coversWrapper}>
                    {covers.map(cover => {
                        return <div style={styles.coverWrapper} onClick={() => {
                            setChoosenCover(cover.url);
                            setStatus('ready');
                        }}>
                            <img src={cover.url} style={styles.cover} />
                        </div>
                    })}
                </div>
            </div>
        )
    }
 
    if (status === 'ready') {
        return (
            <div style={{ ...styles.wrapper }}>
                <div style={{ padding: '15px', backgroundImage: `url(${choosenCover})`, backgroundSize: 'cover' }}>

                    <img src={imageUrl} width='100%' />
                    <div style={styles.startButtonWrapper}>
                        <button
                            style={styles.startButton}
                            onClick={startGame}>
                            <MdPlayCircleOutline size={110} />
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    if (status === 'play') {
        const actionsBtns = [
            { id: 1, label: 'restart', icon: MdSettingsBackupRestore, action: () => setStatus(null) },
            { id: 2, label: 'help', icon: MdHelpOutline, action: () => console.log('help') },
        ];
        return (
            <div>
                <div style={styles.score}>{score}</div>

                <div style={{ ...styles.wrapper }}>
                    <PuzzleBoard
                        choosenCover={choosenCover}
                        level={choosenLevel}
                        imageUrl={imageUrl}
                        restart={() => setStatus(null)}
                        finishGame={() => setStatus('finish')}
                        updateScore={() => setScore(score + 1)}
                        showNumbers={showNumbers} />
                </div>

                {/* <ActionsBtns btns={actionsBtns} /> */}

                {/* <button
                    style={styles.showNumbersTrigger}
                    onClick={() => setShowNumbers(!showNumbers)}>
                    {`${showNumbers ? 'hide' : 'show'} numbers`}
                </button> */}
            </div>
        )
    }

    if (status === 'finish') {
        const actionsBtns = [
            { id: 1, label: 'restart', icon: MdSettingsBackupRestore, action: () => setStatus(null) },
            { id: 2, label: 'share', icon: MdShare, action: () => console.log('share') },
        ];
        return (
            <div style={{ ...styles.wrapper }}>
                <h2 style={{ direction: 'ltr', textAlign: 'center', fontWeight: 'bold', margin: '10px 0' }}>Congratulations!</h2>
                <div style={{ position: 'relative', padding: '15px', backgroundImage: `url(${choosenCover})` }}>
                    <img src={imageUrl} width='100%' style={{ opacity: '.9' }} />

                    {/* <Element style={styles.fireworksWrapper}>
                        <Fireworks />
                    </Element> */}
                </div>

                {/* <div style={styles.startButtonWrapper}>
                    <h2 style={{ direction: 'ltr', fontWeight: 'bold', textAlign: 'center', margin: '20px 0' }}>WELL DONE!</h2>
                    <ShareFacebook url={`https://play.youdoadventures.com/happy-valentine/?id=${adventureId}`}
                        quote={'Happy Valentine!'} />
                </div> */}
                <ActionsBtns btns={actionsBtns} />

                <Element textAlign={'center'}>
                    <button style={{ background:  'blue', border: 0, fontWeight: 'bold', padding: '5px 10px' }}>
                        <a href={'https://youdoadventures.com/valentine'} target={'_blank'}>
                            Create your greeting card
                    </a>
                    </button>
                    {/* <ShareFacebook url={`https://play.youdoadventures.com/happy-valentine/?id=${adventureId}`}
                        quote={'Happy Valentine!'} /> */}
                </Element>
            </div>
        )
    }

    if (status === 'visitor') {
        return (
            <div style={{ ...styles.wrapper }}>
                <h2 style={{ direction: 'ltr', fontWeight: 'bold', textAlign: 'center', margin: '20px 0' }}>
                    I solved my greeting card!
                    </h2>
                <div style={{ padding: '15px', backgroundImage: `url(${choosenCover})` }}>

                    <img src={imageUrl} width='100%' />


                </div>

                <div style={{ ...styles.startButtonWrapper }}>
                    {/* <h2>Create your greeting card</h2> */}
                    <button style={{ background: 'pink', border: 0, fontWeight: 'bold', padding: '5px 10px' }}>
                        <a href={'https://youdoadventures.com/valentine'}>
                            Create your greeting card
                        </a>
                    </button>

                </div>
            </div>
        )
    }
};

const styles = {
    wrapper: {
        paddingBottom: '15px',
        paddingTop: '15px',
        backgroundSize: 'cover',
        position: 'relative',
        maxWidth: '400px',
        margin: '0 auto'
    },
    levelButtonsWrapper: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        textAlign: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
    },
    levelButtonWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    levelButton: {
        border: 0,
        fontSize: '15px',
        fontWeight: 'bold',
        width: '90px',
        height: '90px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    score: {
        borderRadius: '50%',
        backgroundColor: 'lightBlue',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '80px',
        height: '80px',
        margin: '10px 0',
        fontSize: '22px',
        margin: '0 auto 20px'
    },
    coversWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    coverWrapper: {
        width: '20%'
    },
    cover: {
        width: '100%'
    },
    startButtonWrapper: {
        textAlign: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        top: 0,
        left: 0,
        // backgroundColor: 'rgba(0,0,0,0.2)'
    },
    startButton: {
        fontSize: '30px',
        fontWeight: 'bold',
        border: 0,
        padding: '0',
        textTransform: 'uppercase',
        // width: '15rem',
        // height: '15rem',
        borderRadius: '50%',
        backgroundColor: 'red'
    },
    showNumbersTrigger: {
        marginTop: '10px',
        opacity: 0,
        display: 'none'
    },
    fireworksWrapper: {
        position: 'absolute',
        // width: '100%',
        // height: '100%',
        // zIndex: 2,
        top: '50%',
        left: '50%',
        transform: 'translate(-50% -50%)'
    }
}

export default ToolPuzzle; 