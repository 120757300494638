import React from "react";
import { HBox, VBox } from 'react-stylesheet';
import { Link } from 'react-router-dom';
import { Element } from 'react-stylesheet';
import { Container } from "react-grid-system";
import { MdMic, MdPhone, MdPauseCircleOutline, MdApps, MdPersonOutline, MdEdit } from 'react-icons/md';
import { GoSync, GoPlus, GoUnmute } from 'react-icons/go';

import { gutters, colors } from "../../00_base/variables";
import { Circle } from "../../01_atoms";




class ScreenPhoneCall extends React.Component {
    constructor(props) {
        super(props);
        const audio = this.props.location.audio;

        if (!audio) {
            window.location.href = `/tool/אנשי%20קשר`;
        } else {
            this.state = {
                audio: audio,
                play: true,
                videoType: false,
                number: this.props.location.number
            }
        }

        this.toggleAudio = this.toggleAudio.bind(this);
    }

    componentDidMount() {
        const { audio } = this.state;

        if (audio) {
            this.setState({
                videoType: audio.includes('mp4') || audio.includes('MOV'),
                imageType: audio.includes('jpg') || audio.includes('png')
            })
        }
    }

    toggleAudio() {
        if (document.getElementById('audio').paused) {
            document.getElementById('audio').play();
            this.setState({
                play: true
            })
        } else {
            document.getElementById('audio').pause();
            this.setState({
                play: false
            })
        }
    }

    render() {
        const { background, name, closeScreen, } = this.props.location,
            { videoType, imageType, play, audio } = this.state;
        return (
            <Element style={{ backgroundImage: `linear-gradient(to bottom left, #0e3441, #0c1323)` }}>
                <Container style={{ padding: '0' }}>
                    <div style={panel}>
                        {!videoType && !imageType &&
                            <Element height={videoType ? '20vh' : 'auto'} paddingTop={!videoType && !imageType && '5rem'}>
                                <Circle size={80} background={colors.white} textAlign={'center'} style={{margin: '0 auto'}}>
                                    {/* <img src={MdPersonOutline}
                                     width={'100px'}/> */}
                                    <MdPersonOutline size={50} color={colors.black} />
                                </Circle>

                                <Element padding={'2rem 0'} color={colors.white} textAlign={'center'}>
                                    <h2 style={{ fontWeight: 'bold', marginTop: '0' }}>{name}</h2>
                                    <h3 style={{ fontWeight: 'normal' }}>{this.state.number}</h3>
                                </Element>
                            </Element>
                        }

                        <VBox justifyContent={'center'} height={videoType || imageType ? '100vh' : '60vh'} paddingTop={videoType ? '0' : 'initial'}>
                            {imageType &&
                                <HBox height={'100%'} alignItems={'center'}>
                                    <img width={'100%'} src={audio} />
                                </HBox>
                            }
                            {videoType ?
                                <HBox justifyContent={'center'} height={'100%'}>
                                    <video width="100%" id={'audio'} autoPlay={true}>
                                        <source src={audio} type="video/mp4" />
                                    </video>
                                </HBox>
                                :
                                <Element>
                                    <audio src={audio}
                                        id={'audio'}
                                        autoPlay={true}
                                        style={{ position: 'absolute', opacity: '0' }} />
                                    {!imageType &&
                                        <PhoneIcons urlId={this.props.urlId} />
                                    }
                                </Element>
                            }


                            <HBox justifyContent={'space-between'} alignItems={'center'}
                                style={{ position: videoType || imageType ? 'absolute' : 'static', width: '100%', bottom: '40px' }}>
                                <Element style={phoneIcon}>
                                    <Link to={`/menu`}>
                                        <MdApps color={colors.white} size={35} />
                                    </Link>
                                </Element>

                                <Link to={`/tool/אנשי%20קשר`}>
                                    <Circle background={'#f24139'} size={65}>
                                        <MdPhone size={27} color={colors.white} />
                                    </Circle>
                                </Link>

                                <Element style={{ ...phoneIcon, ...!play && disable }} onClick={this.toggleAudio}>
                                    <GoUnmute size={35} />
                                </Element>
                            </HBox>
                        </VBox>
                    </div>
                </Container>
            </Element>
        )
    }
}


class PhoneIcons extends React.Component {
    render() {
        const { urlId } = this.props;
        return (
            <HBox flexWrap={'wrap'}>
                <Element style={phoneIcon}>
                    <MdMic color={colors.white} size={35} />
                </Element>
                <Element style={{ ...phoneIcon, ...disable }}>
                    <MdPauseCircleOutline color={colors.white} size={35} />
                </Element>
                <Element style={{ ...phoneIcon, ...disable }}>
                    <MdEdit />
                </Element>
                <Element style={phoneIcon}>
                    <Link to={`/tool/אנשי%20קשר`}>
                        <MdPersonOutline color={colors.white} size={35} />
                    </Link>
                </Element>
                <Element style={{ ...phoneIcon, ...disable }}>
                    <GoPlus color={colors.white} size={35} />
                </Element>
                <Element style={phoneIcon}>
                    <Link to={`/tool/אנשי%20קשר`}>
                        <GoSync color={colors.white} size={35} />
                    </Link>
                </Element>
            </HBox>
        )
    }
}


const panel = {
    position: 'relative',
    top: '0',
    zIndex: '1',
    width: '100%',
    padding: '-',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
},
    backButton = {
        position: 'absolute',
        width: '100%',
        // height: '50%',
        bottom: '0',
        margin: 'auto',
        left: '0',
        display: 'flex',
        alignItems: 'center'
    },
    link = {
        width: '100%',
        height: '100%',
        display: 'block',
    };


const phoneIcon = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '33.33%',
    padding: `${gutters.sm} ${gutters.sm}`,
    color: colors.white,
    fontSize: '3rem'
};

const disable = {
    opacity: '0.4'
};

export default ScreenPhoneCall;
