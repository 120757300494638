import React from 'react';
import PropTypes from "prop-types";
import { gutters, colors } from '../../00_base/variables/';
import styled from 'styled-components';
import { Heading, Paragraph, Image } from '../../01_atoms';
import { Card1, Card2, Card3 } from './card-types';




const Card = props => {
    const {
        cardType,
        wrapperStyle,
        title,
        titleStyle,
        subtitle,
        subtitleStyle,
        description,
        descriptionStyle,
        image,
        imageStyle,
        actions,
        actionsStyle,
        actionItemStyle,
        bodyHeight,
        contentComponent } = props;


    const cardData = { 
        title, 
        subtitle,
        description,
        image,
        actions
    };
    const cardSytling = { 
        titleStyle: {...defaultCardStyle.title, ...titleStyle}, 
        subtitleStyle: {...defaultCardStyle.subtitle, ...subtitleStyle},
        descriptionStyle: {...defaultCardStyle.description, ...descriptionStyle}, 
        imageStyle: {...defaultCardStyle.image, ...imageStyle},
        actionsStyle: {...defaultCardStyle.actions, ...actionsStyle},
        actionItemStyle: actionItemStyle,
        bodyHeight
    }
    
    const CardByType = () => {
        switch (cardType) {
            case 1: return <Card1 {...cardData} {...cardSytling} />;
            case 2: return <Card2 {...cardData} {...cardSytling} />;
            case 3: return <Card3 {...cardData} {...cardSytling} />;
            default: return <div>{props.children}</div>
        }
    }


    return (
        <CardStyle c1assName={'card'} {...props} style={defaultCardStyle.wrapper, wrapperStyle}>
            <CardContainer>
                <CardByType/>
            </CardContainer>
        </CardStyle>
    )
};


const defaultCardStyle = {
    wrapper: {
        // borderRadius: '30px',
        backgroundColor: colors.gray_1
    },
    title: {

    },
    subtitle: {
        marginTop: gutters.xs,
        marginBottom: gutters.xs
    },
    description: {

    },
    actions: {
        display: 'flex'
    }
};

const CardStyle = styled.div`;
    overflow: hidden;
    ${props => `
        background-color: ${props.backgroundColor};
        color: ${colors.variants[props.variant].color};
        box-shadow: ${props.boxShadow};
        ${props.borderRadius && `border-radius: ${props.borderRadius}`};
        width: ${props.width};
        padding: ${props.padding}; 
    `}
`;

const CardContainer = styled.div`

`;


Card.defaultProps = {
    width: 'auto',// full-width
    layout: 1,
    variant: 'primary',
    // boxShadow: '.2rem .2rem rgba(0,0,0,0.4)',
    borderRadius: '10px',
    backgroundColor: colors.gray_1,
    padding: 0,

    

    title: '',
    subtitle: '',
    description: '',
    wrapperStyle: defaultCardStyle.wrapper,
    titleStyle: defaultCardStyle.title,
    subtitleStyle: defaultCardStyle.subtitle,
    descriptionStyle: defaultCardStyle.description,
    actionsStyle: defaultCardStyle.description,
};

Card.propTypes = {
    wrapperStyle:PropTypes.object,
    title: PropTypes.string,
    titleStyle: PropTypes.object,
    subtitle: PropTypes.string,
    subtitleStyle: PropTypes.object,
    description: PropTypes.string,
    descriptionStyle: PropTypes.object,
    actionsStyle: PropTypes.object,
    image: PropTypes.string,
    imageStyle: PropTypes.object,
    contentComponent: PropTypes.element,

    cardType: PropTypes.number,
    width: PropTypes.string,
    // variant: PropTypes.oneOf(['primary', 'secondary', 'success', 'error', 'warning']),
    backgroundImage: PropTypes.string,
    boxShadow: PropTypes.string,
    borderRadius: PropTypes.string,
    // layout: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string
};

export default Card;
