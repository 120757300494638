import React, { useState } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';

import {
    ModalWrapper,
    ModalContent,
    ModalTitle,
    ModalSubtitle,
    ModalDescription,
    ModalCloseTrigger
} from './modal-styles';
import Button from '../../01_atoms/button/a-button';
import { colors } from '../../00_base/variables/v-colors';
import AnimateSlide from '../../00_base/animations/animate-slide';

const Modal = ({
    title,
    subtitle,
    animationDirection,
    animationSpeed,
    description,
    closeTrigger,
    closePreActions,
    contentStyle,
    triggerValue,
    triggerComponent,
    triggerStyle,
    name,
    ...props
}) => {
    const [isActive, setIsActive] = useState(false);
    const ToggleModal = triggerComponent;
    const className = classNames('app-modal', {
        [`app-modal-${name}`]: !!name
    });

    const closeModalHandler = () => {
        setIsActive('closing');
        setTimeout(() => {
            setIsActive(false)
        }, 1200)
    }

    return (
        <div>
            <ToggleModal onClick={() => setIsActive('active')} style={{ ...triggerStyle, cursor: 'pointer' }}>
                {triggerValue || 'open modal'}
            </ToggleModal>

            <ModalWrapper {...props} animate={animationDirection} speed={animationSpeed} className={`${className} ${isActive ? isActive : 'hidden'}`}>
                <ModalCloseTrigger style={closeTrigger.style} onClick={closeModalHandler}>
                    {closeTrigger.symbol}
                </ModalCloseTrigger>
                <ModalContent {...contentStyle}>
                    {title && <ModalTitle tagName={'h2'}>{title}</ModalTitle>}
                    {subtitle && <ModalSubtitle tagName={'p'}>{subtitle}</ModalSubtitle>}
                    {description && <ModalDescription tagName={'p'}>{description}</ModalDescription>}
                    {props.children || props.content}
                </ModalContent>
            </ModalWrapper>

        </div>
    )
};

Modal.defaultProps = {
    title: '',
    subtitle: '',
    description: '',
    backgroundColor: 'rgba(0,0,0,0.9)',
    content: <div>Modal Content</div>,
    contentStyle: { width: '500px', backgroundColor: colors.white, padding: '20px' },
    triggerValue: 'open modal',
    triggerComponent: Button,

    animationDirection: 'lr',
    animationSpeed: '.4s',
    closeTrigger: {
        style: { width: '40px', height: '40px', fontSize: '20px', color: colors.white },
        symbol: 'X'
    }
};

Modal.propTypes = {
    /**
    The title of the modal.
     */
    title: PropTypes.string,
    /**
    The subtitle of the modal.
     */
    subtitle: PropTypes.string,
    /**
    The description of the modal.
     */
    description: PropTypes.string,
    /**
    The background-color of the modal.
     */
    backgroundColor: PropTypes.string,
    /**
    The content of the modal.
     */
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType, PropTypes.element]),
    /**
    The style of the modal's content.
     */
    contentStyle: PropTypes.object,
    /**
    The value of the Modal's trigger.
     */
    triggerValue: PropTypes.string,
    /**
    Provide a component for the Modal's trigger.
     */
    triggerComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    triggerStyle: PropTypes.object,
    /**
    Provide a closeTrigger symbol and styling
    */
    closeTrigger: PropTypes.shape({ symbol: PropTypes.oneOfType([PropTypes.element, PropTypes.number]), style: PropTypes.object }),
    /**
    The direction of the Modal opening animation
    tb = top to bottom; bt = bottom to top; rl = right to left; lr = left to right;)
    */
    animationDirection: PropTypes.oneOf(['tb', 'bt', 'rl', 'lr']),
    /**
    The speed of the Modal opening animation 
    */
    animationSpeed: PropTypes.string
};

export default Modal;

