/* Main colors */
const main = {
    primary: '#00a647',
    primary_light: '#00a647',
    primary_dark: '#00a647',
    primary_hover: '#00a647',
    secondary: '#368b7f',
    secondary_light: '#368b7f',
    secondary_dark: '#29665d',
    secondary_hover: '#368b7f'
};


/* Shades */
const shades = {
    white: '#ffffff',
    gray_1: '#e8e7e8',
    gray_2: '#d1d0d2',
    gray_3: '#bbbbbc',
    gray_4: '#a5a5a6',
    gray_6: '#7b7a7d',
    gray_10: '#2e2e31',
    black: '#000000'
};

/* Feedback and Action colors  */
const feedbacks = {
    success: 'green',
    success_light: 'green',
    success_dark: 'green',
    success_hover: 'green',
    error: 'orange',
    error_light: 'orange',
    error_dark: 'orange',
    error_hover: 'orange',
    warning: 'yellow',
    warning_light: 'yellow',
    warning_dark: 'yellow',
    warning_hover: 'yellow'
};

/* typography */
const typography = {
    text: shades.gray_10,
    text_heading: shades.black,
    text_subtitle: shades.gray_6,
    link: main.primary,
    link_visited: main.primary_dark
};

/* border */
const borders = {
    border: shades.gray_2
};

/* body */
const body = {
    body: shades.white
};

/* variants */
const variants = {
    primary: {color: shades.white, backgroundColor: main.primary, colorHover:shades.gray_1, backgroundColorHover: main.primary_hover},
    secondary: {color: shades.white, backgroundColor: main.secondary, colorHover:shades.gray_1, backgroundColorHover: main.secondary_hover},
    success: {color: shades.white, backgroundColor: feedbacks.success, colorHover:shades.gray_1, backgroundColorHover: feedbacks.success_hover},
    error: {color: shades.white, backgroundColor: feedbacks.error, colorHover:shades.gray_1, backgroundColorHover: feedbacks.error_hover},
    warning: {color: shades.black, backgroundColor: feedbacks.warning, colorHover:shades.gray_1, backgroundColorHover: feedbacks.warning_hover},
    disable: {color: shades.gray_6, backgroundColor: shades.gray_2},
    link: {color: typography.link, backgroundColor: 'transparent', colorHover:shades.gray_1, backgroundColorHover: typography.link_visited},
};

/* layout colors */
const layoutColors = {
    headerTitle: shades.black,
}

export const colors = {
    ...main,
    ...shades,
    ...feedbacks,
    ...typography,
    ...borders,
    ...body,
    ...layoutColors,
    variants
};