import React from 'react';
import styled, { keyframes } from 'styled-components';


const slideIn_tb = keyframes`
    from {transform: translateY(-100%);}         
    to {transform: translateY(0);} 
`;

const slideIn_bt = keyframes`
    from {transform: translateY(100%);}         
    to {transform: translateY(0);} 
`;
const slideIn_rl = keyframes`
    from {transform: translateX(100%);}         
    to {transform: translateX(0);} 
`;
const slideIn_lr = keyframes`
    from {transform: translateX(-100%);}         
    to {transform: translateX(0);} 
`;

const slideOut_tb = keyframes`
    from {transform: translateY(0);}         
    to {transform: translateY(100%);} 
`;

const slideOut_bt = keyframes`
from {transform: translateY(0);}         
to {transform: translateY(-100%);} 
`;
const slideOut_rl = keyframes`
    from {transform: translateX(0);}         
    to {transform: translateX(-100%);} 
`;
const slideOut_lr = keyframes`
    from {transform: translateX(0);}         
    to {transform: translateX(100%);} 
`;
 
// const AnimateSlide = ({direction, movement}) => {
//     const animation = direction === 'in' ? slideIn : slideOut;
//     debugger;
//     return (
//         <Slide animation={`1s ${animation[movement]} ease-out infinite;`}/>
//     )
// }
//  ${props => `${slide[props.direction[props.movement]]} 2s ease-out;`};
const AnimateSlide = styled.div`
    transform: translateY(${props => props.starterPoint});

    .active & {
        animation:  ${props => props.speed ? props.speed : '1s'} ${props => 
            props.dir === 'tb' ? slideIn_tb : 
            props.dir === 'bt' ? slideIn_bt :
            props.dir === 'rl' ? slideIn_rl :
            props.dir === 'lr' ? slideIn_lr : 
            slideIn_bt} forwards;
        animation-delay:  ${props => props.delay};
    }
    .closing & {
        transform: translateY(0);
        animation:  ${props => props.speed ? props.speed : '1s'} ${props => 
            props.dir === 'tb' ? slideOut_tb : 
            props.dir === 'bt' ? slideOut_bt :
            props.dir === 'rl' ? slideOut_rl :
            props.dir === 'lr' ? slideOut_lr : 
            slideOut_bt} forwards;
        animation-delay:  ${props => props.delay};
    }
`;

    export default AnimateSlide;