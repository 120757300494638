import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Element } from 'react-stylesheet';
import { colors, zIndex } from '../../00_base/variables';
import { Link } from 'react-router-dom';
import { Icon } from '../../01_atoms';
// import { faBars, faUser } from '@fortawesome/free-solid-svg-icons';

const Navigation = ({
    items,
    defaultActiveItem,
    dir,
    childDir,
    style,
    listStyle,
    itemStyle,
    childNavigationStyle,
    activeItemStyle,
    spaceBetweenItems,
    hiddenNavigation,
    navWrapperStyle,
    navWrapperHiddenStyle,
    navigationComponent
}) => {
    const [activeItemId, setActiveItemId] = useState(defaultActiveItem || items[0].id);
    const [hiddenNavigationActive, setHiddenNavigationActive] = useState(false);
    const renderItem = item => {
        const isActive = activeItemId === item.id;
        let NavLink;
        if (item.route && !isActive) {
            NavLink = () => <Link to={item.href} style={navLink}>{item.label}</Link>;
        } else {
            NavLink = () => <a href={!!item.childNavigation || isActive ? 'javascript:void(0)' : !!item.href ? item.href : 'javascript:void(0)'} style={navLink}>{item.label}</a>;
        }
        return (
            <NavItem
                key={item.id}
                spaceBetweenItems={spaceBetweenItems}
                onClick={() => !isActive && setActiveItemId(isActive ? -1 : item.id)}
                style={{ ...itemStyle, ...item.style, ...isActive && activeItemStyle }}>
                <NavLink />
            </NavItem>
        )
    }

    const activeItem = items.find(item => item.id === activeItemId);


    const hiddenWrapperStyle = {
        width: '100%',
        zIndex: zIndex.base,
        ...hiddenNavigation && navWrapperDefaultStyle,
        ...navWrapperStyle,
        ...!hiddenNavigationActive && hiddenNavigation && navWrapperHiddenStyle
    }

    return (
        <Nav style={style} className={'navigation'}>

            {!!hiddenNavigation &&
                <Element width={'55px'}>
                    <Icon
                        style={{ position: 'relative', zIndex: zIndex.medium }}
                        icon={hiddenNavigationActive ? hiddenNavigation.closeIcon : hiddenNavigation.openIcon}
                        onClick={() => setHiddenNavigationActive(!hiddenNavigationActive)}
                        color={'#fff'} />
                </Element>
            }


            <Element style={{ ...hiddenNavigation && hiddenWrapperStyle, width: '100%' }}>
                {navigationComponent ? navigationComponent :
                    <>
                        <NavList dir={dir} listStyle={listStyle}>
                            {items.map(renderItem)}
                        </NavList>

                        {activeItem && activeItem.childNavigation &&
                            <ChildNavList style={childNavigationStyle} dir={childDir}>
                                {activeItem.childNavigation.map(renderItem)}
                            </ChildNavList>
                        }
                    </>
                }
            </Element>
        </Nav>
    )
}

const navLink = {
    color: 'inherit',
    textDecoration: 'none'
}
const Nav = styled.nav`
`;

const NavList = styled.ul`
    display: flex;
    flex-direction: ${props => props.dir};
    list-style: none;
    padding: 0;
    margin: 0;
`;
const NavItem = styled.li`
    margin-right: ${props => props.spaceBetweenItems};
    padding: 10px;
    cursor: pointer;
`;

const ChildNavList = styled.ul`
    display: flex;
    position: absolute;
    flex-direction: ${props => props.dir};
    list-style: ${props => props.listStyle || 'none'};
    padding: 0;
    margin: 0;
`;


const navWrapperDefaultStyle = {
    width: '100%',
    backgroundColor: '#000',
    position: 'fixed',
    left: 0,
    width: 0,
    top: 0,
    bottom: 0,
    padding: '50px',
    transform: 'translateX(0)',
    transition: 'transform .5s',
    overflow: 'hidden'
};

Navigation.defaultProps = {
    dir: 'row',
    childDir: 'column',
    listStyle: 'none',
    spaceBetweenItems: '0',
    itemStyle: {
        padding: '10px'
    },
    activeItemStyle: {
        textDecoration: 'underline'
    },
    childNavigationStyle: {
        backgroundColor: colors.black
    },
    navWrapperStyle: {
        width: '100%',
        backgroundColor: '#000',
        position: 'fixed',
        left: 0,
        width: 0,
        top: 0,
        bottom: 0,
        padding: '50px',
        transform: 'translateX(0)',
        transition: 'transform .5s',
        overflow: 'hidden'
    },
    navWrapperHiddenStyle: {
        transform: 'translateX(-100%)'
    }
};
Navigation.propTypes = {
    items: PropTypes.array.isRequired,
    dir: PropTypes.oneOf(['row', 'column']),
    listStyle: PropTypes.string,
    spaceBetweenItems: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    itemStyle: PropTypes.object,
    activeItemStyle: PropTypes.object,
    childDir: PropTypes.oneOf(['row', 'column']),
    childNavigationStyle: PropTypes.object,
    hiddenNavigation: PropTypes.object,
    navWrapperStyle: PropTypes.object,
    navWrapperHiddenStyle: PropTypes.object,
};

export default Navigation;