class Tool {
    constructor(id, title, description, icon, color, password, toolData, answer, component, cluesData) {
        this.id = id;
        this.title = title;
        this.name = description;
        this.icon = icon;
        this.color = color;
        this.password = password;
        this.toolData = toolData;
        this.answer = answer;
        this.component = component;
        this.cluesData = cluesData;
    }
};

export default Tool;






// class Tool {
//     constructor(id, title, description, icon, color, password, toolData, answer, component) {
//         this.id = id;
//         this.title = title;
//         this.name = description;
//         this.icon = icon;
//         this.color = color;
//         this.password = password;
//         this.toolData = toolData;
//         this.answer = answer;
//         this.component = component
//     }
// };