import React from 'react';
import styled, { keyframes } from 'styled-components';

export const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; } 
`;
export const fadeout = keyframes`
    from { opacity: 1; }
    to { opacity: 0; } 
`;

export const slideIn = keyframes`
    from { transform: translateX(100%); }         
    to { transform: translateX(0); } 
`;
export const slideOut = keyframes`
    from { transform: translateX(0); }     
    to { transform: translateX(100%); } 
`;

export const rotate = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); } 
`;
