import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import tfLogo from '../../../assets/images/tf-logo.png';
// import Image from "../image/image";
import Image from "../../01_atoms/image";
import { Circle } from '../../01_atoms';
import TfLogo from '../../01_atoms/svgs/tf-logo';

const AppLogo = ({ width, circle, circleSize, style }) => {
    if (circle) {
        return (
            <Circle size={circleSize} style={style}>
                <a href={'/'}>
                    <Image
                        src={'https://www.tf-interactive.com//wp-content/themes/allenby/images/tf-logo-vector.svg'}
                        width={width || 100}
                        style={{ display: 'block' }} />
                </a>
            </Circle>
        )
    }
    return (
        <div style={{ overflow: 'hidden', ...style }} className={'app-logo'}>
            <a href={'/'}>
                <TfLogo/>
                {/* <Image src={'https://www.tf-interactive.com//wp-content/themes/allenby/images/tf-logo-vector.svg'} width={width || 100} /> */}
            </a>
        </div>
    )
}

AppLogo.propTypes = {
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    circle: PropTypes.bool
};
export default AppLogo;