import React from 'react';
import styled from "styled-components/";
import { HBox, Element } from 'react-stylesheet';
import { font_size, gutters } from '../../00_base/variables';

const Viewport = {
    xs: '320px',
    xs_l: '480px',
    sm: '640px',
    md: '768px',
    lg: '992px',
    xl: '1080px',
    wide: '1200px',
    widest: '1920px'
}

class TifzoretBoard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            correctItems: this.props.correctItems,
            wrongItems: this.props.wrongItems,
            usedCorrectLength: 0,
            usedWrongLength: 0,
            number_code: this.props.number_code
        };
         this.handleCorrectChange = this.handleCorrectChange.bind(this);
        this.handleWrongChange = this.handleWrongChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleThumbnailChange = this.handleThumbnailChange.bind(this);
        this.save = this.save.bind(this);
        this.buildTable = this.buildTable.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    // componentDidUpdate(prevProps) {
    //     const prevCorrect = prevProps.data.correctItems;
    //     const prevWrong = prevProps.data.wrongItems;
    //     const {correctItems, wrongItems} = this.props.data;
    //
    //
    //     if (correctItems !== prevCorrect) {
    //         this.setState({
    //             correctItems: correctItems
    //         })
    //     }
    //     if (wrongItems !== prevWrong) {
    //         this.setState({
    //             wrongItems: wrongItems
    //         })
    //     }
    // }

    componentWillMount() {
         let { correctItems, wrongItems } = this.state,
            correct = [],
            wrong = [];

        const correctLength = correctItems.length,
            wrongLength = wrongItems.length;

        if (correctLength > 0) {
            for (let i = 0; i < 50; i++) {
                if (correctLength * 10 <= i) {
                    correct.push(correctItems[i - correctLength * 10]);
                } else if (correctLength * 9 <= i) {
                    correct.push(correctItems[i - correctLength * 9]);
                } else if (correctLength * 8 <= i) {
                    correct.push(correctItems[i - correctLength * 8]);
                } else if (correctLength * 7 <= i) {
                    correct.push(correctItems[i - correctLength * 7]);
                } else if (correctLength * 6 <= i) {
                    correct.push(correctItems[i - correctLength * 6]);
                } else if (correctLength * 5 <= i) {
                    correct.push(correctItems[i - correctLength * 5]);
                } else if (correctLength * 4 <= i) {
                    correct.push(correctItems[i - correctLength * 4]);
                } else if (correctLength * 3 <= i) {
                    correct.push(correctItems[i - correctLength * 3]);
                } else if (correctLength * 2 <= i) {
                    correct.push(correctItems[i - correctLength * 2]);
                } else if (correctLength <= i) {
                    correct.push(correctItems[i - correctLength]);
                } else {
                    correct.push(correctItems[i]);
                }
            }
        }
        if (wrongLength > 0) {
            for (let i = 0; i < 50; i++) {
                if (correctLength * 13 <= i) {
                    wrong.push(wrongItems[i - wrongLength * 13]);
                } else if (correctLength * 12 <= i) {
                    wrong.push(wrongItems[i - wrongLength * 12]);
                } else if (correctLength * 11 <= i) {
                    wrong.push(wrongItems[i - wrongLength * 11]);
                } else if (correctLength * 10 <= i) {
                    wrong.push(wrongItems[i - wrongLength * 10]);
                } else if (correctLength * 9 <= i) {
                    wrong.push(wrongItems[i - wrongLength * 9]);
                } else if (correctLength * 8 <= i) {
                    wrong.push(wrongItems[i - wrongLength * 8]);
                } else if (correctLength * 7 <= i) {
                    wrong.push(wrongItems[i - wrongLength * 7]);
                } else if (correctLength * 6 <= i) {
                    wrong.push(wrongItems[i - wrongLength * 6]);
                } else if (correctLength * 5 <= i) {
                    wrong.push(wrongItems[i - wrongLength * 5]);
                } else if (correctLength * 4 <= i) {
                    wrong.push(wrongItems[i - wrongLength * 4]);
                } else if (correctLength * 3 <= i) {
                    wrong.push(wrongItems[i - wrongLength * 3]);
                } else if (correctLength * 2 <= i) {
                    wrong.push(wrongItems[i - wrongLength * 2]);
                } else if (correctLength <= i) {
                    wrong.push(wrongItems[i - wrongLength]);
                } else {
                    wrong.push(wrongItems[i]);
                }
            }
        }
        this.setState({
            tableSize: 20,
            cellSize: 4,
            correct: correct,
            wrong: wrong
        })
    }

    handleChange(e) {
        const target = e.target,
            value = target.value;
        this.setState({
            number_code: value
        });

        // this.props.updateTemplateData('number_code', value)
    }

    handleCorrectChange(value, name, index) {
        if (index) {
            this.state.correctItems[index] = value;
        } else {
            this.state.correctItems = value;
        }

        this.setState({
            correctItems: this.state.correctItems
        })
    }

    handleWrongChange(value, name, index) {
        if (index) {
            this.state.wrongItems[index] = value;
        } else {
            this.state.wrongItems = value;
        }

        this.setState({
            wrongItems: this.state.wrongItems
        })
    }

    save() {
        this.props.liveSubmit(this.state);
        this.props.toggleLiveMode();
    }

    handleThumbnailChange(url) {
        this.setState({
            thumbnail: url
        })
    }

    handlePasswordChange(e) {
        this.setState({
            password: e.target.value
        })
    }

    buildTable(number_code) {
        let bigNumbers = [];
        const { correctItems, wrongItems } = this.state;
        const numberCodeLength = number_code.length;
        let numberWidth = '33.33%';
        let correct = correctItems.concat(correctItems).concat(correctItems);
        let wrong = wrongItems.concat(wrongItems).concat(wrongItems);

        switch (numberCodeLength) {
            case 2:
                numberWidth = '50%';
                break;
            case 3:
                numberWidth = '33.33%';
                break;
            case 4:
                numberWidth = '25%';
                break;
            case 5:
                numberWidth = '20%';
                break;
            case 6:
                numberWidth = '16.667%';
                break;
        }

        function isActiveCell(digit, index) {
            let isActive = false;

            switch (digit) {
                case '0':
                    isActive = [0, 1, 2, 4, 6, 8, 10, 12, 14, 16, 17, 18].some(n => n === index);
                    break;
                case '1':
                    isActive = [0, 1, 4, 8, 12, 16].some(n => n === index);
                    break;
                case '2':
                    isActive = [2, 1, 0, 4, 8, 9, 10, 14, 18, 17, 16].some(n => n === index);
                    break;
                case '3':
                    isActive = [2, 1, 0, 4, 8, 9, 10, 12, 16, 17, 18].some(n => n === index);
                    break;
                case '4':
                    isActive = [2, 6, 10, 9, 8, 0, 4, 12, 16].some(n => n === index);
                    break;
                case '5':
                    isActive = [2, 1, 0, 6, 8, 9, 10, 12, 18, 17, 16].some(n => n === index);
                    break;
                case '6':
                    isActive = [2, 1, 0, 6, 8, 9, 10, 12, 14, 18, 17, 16].some(n => n === index);
                    break;
                case '7':
                    isActive = [2, 1, 0, 4, 9, 13, 18].some(n => n === index);
                    break;
                case '8':
                    isActive = [2, 1, 0, 4, 6, 8, 9, 10, 12, 14, 16, 17, 18].some(n => n === index);
                    break;
                case '9':
                    isActive = [2, 1, 0, 4, 6, 8, 9, 10, 12, 16, 17, 18].some(n => n === index);
                    break;
            }

            return isActive;
        }

        function buildCells(digit, numberCodeLength, index) {
            const cells = [];
            for (let c = 0; c < 20; c++) {
                const isActive = isActiveCell(digit, c);
                const image = isActive ? correct.shift() : wrong.shift();
                cells.push(
                    <Item key={c}
                        c={c}
                        index={index}
                        digit={digit}
                        isActive={isActive}
                        numberCodeLength={numberCodeLength}
                        backgroundImage={`url(${image})`} />
                )
            }
            return cells;
        }

        for (let n = 0; n < numberCodeLength; n++) {
            bigNumbers.push(
                <Number key={n}
                    digit={number_code[n]}
                    style={{ width: numberWidth }}>
                    {buildCells(number_code[n], numberCodeLength, n)}
                </Number>)
        }

        return (
            <Element>
                {/*<Matriza direction={'horizontal'} numberCodeLength={numberCodeLength}/>*/}
                <HBox>
                    {/*<Matriza direction={'vertical'}/>*/}
                    <HBox flexWrap={'wrap'}
                        justifyContent={'center'}
                        flexDirection={'row-reverse'}
                        alignItems={'center'}
                        maxWidth={'100%'}
                        style={{ flex: 1 }}>
                        {bigNumbers}
                    </HBox>
                </HBox>
            </Element>
        );
    }

    render() {
        const { liveEdit, toggleLiveMode, removeTool, showEditButton } = this.props;
        const { title, description, thumbnail, advancedItems, password } = this.state;
        const { correctItems, wrongItems } = this.state;
        const { number_code } = this.state, { hideTitle, hideNumberCode } = this.props,
            table = number_code && this.buildTable(number_code);

        return (
            <Element width={'100%'}>
                <HBox flexWrap={'wrap'}>
                    <Element width={'100%'}>
                        {table}
                    </Element>
                </HBox>
            </Element>
        )
    }

    static defaultProps = {
        data: {
            title: 'תפזורת',
            correctItems: [],
            wrongItems: [],
            thumbnail: 'https://admin.youdoadventures.com/wp-content/uploads/2018/07/תמונת-כפתור-לגלריית-תמונות.png.jpg',
            slug: 'tifzoret',
            number_code: '1234'
        }
    }
}


class Item extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mark: false
        };
        this.markItem = this.markItem.bind(this);
        this.unmarkItem = this.unmarkItem.bind(this);
    }

    markItem() {
        this.setState({
            mark: true
        });

        setTimeout(() => {
            this.unmarkItem();
        }, 1400);
    }

    unmarkItem() {
        this.setState({
            mark: false
        })
    }

    f

    render() {
        const { c, index, isActive, digit, correct, wrong, backgroundImage, numberCodeLength, matrizaHelper } = this.props,
            { mark } = this.state;

        const letters = ['א', 'ב', 'ג', 'ד', 'ה'];

        let cellLetter = ``;
        let cellNumber = ``;

        switch (c) {
            case 0:
            case 1:
            case 2:
            case 3:
                cellLetter = letters[0];
                break;
            case 4:
            case 5:
            case 6:
            case 7:
                cellLetter = letters[1];
                break;
            case 8:
            case 9:
            case 10:
            case 11:
                cellLetter = letters[2];
                break;
            case 12:
            case 13:
            case 14:
            case 15:
                cellLetter = letters[3];
                break;
            case 16:
            case 17:
            case 18:
            case 19:
                cellLetter = letters[4];
                break;
        }

        switch (index) {
            case 2:
                switch (c) {
                    case 0:
                    case 4:
                    case 8:
                    case 12:
                    case 16:
                        cellNumber = 1;
                        break;
                    case 1:
                    case 5:
                    case 9:
                    case 13:
                    case 17:
                        cellNumber = 2;
                        break;
                    case 2:
                    case 6:
                    case 10:
                    case 14:
                    case 18:
                        cellNumber = 3;
                        break;
                    case 3:
                    case 7:
                    case 11:
                    case 15:
                    case 19:
                        cellNumber = 4;
                        break;
                }
                break;
            case 1:
                switch (c) {
                    case 0:
                    case 4:
                    case 8:
                    case 12:
                    case 16:
                        cellNumber = 5;
                        break;
                    case 1:
                    case 5:
                    case 9:
                    case 13:
                    case 17:
                        cellNumber = 6;
                        break;
                    case 2:
                    case 6:
                    case 10:
                    case 14:
                    case 18:
                        cellNumber = 7;
                        break;
                    case 3:
                    case 7:
                    case 11:
                    case 15:
                    case 19:
                        cellNumber = 8;
                        break;
                }
                break;
            case 0:
                switch (c) {
                    case 0:
                    case 4:
                    case 8:
                    case 12:
                    case 16:
                        cellNumber = 9;
                        break;
                    case 1:
                    case 5:
                    case 9:
                    case 13:
                    case 17:
                        cellNumber = 10;
                        break;
                    case 2:
                    case 6:
                    case 10:
                    case 14:
                    case 18:
                        cellNumber = 11;
                        break;
                    case 3:
                    case 7:
                    case 11:
                    case 15:
                    case 19:
                        cellNumber = 12;
                        break;
                }
                break;
        }

        return (
            <ItemStyle key={c}
                data-index={index}
                data-digit={digit}
                className={`cell ${isActive && 'active'}`}
                onClick={matrizaHelper && this.markItem}
                numberCodeLength={numberCodeLength}>
                <Element className={'cell-image'} style={{ backgroundImage: backgroundImage }}>

                    <HBox justifyContent={'center'}
                        alignItems={'center'}
                        style={{
                            ...cover,
                            opacity: mark ? '1' : '0'
                        }}>
                        {`${cellLetter}${cellNumber} `}
                    </HBox>
                </Element>

            </ItemStyle>
        )
    }
}

class Matriza extends React.Component {
    constructor(props) {
        super(props);

        this.buildMatriza = this.buildMatriza.bind(this);
    }

    buildMatriza() {
        const { numberCodeLength, direction } = this.props,
            matriza = [];
        const horizontal = direction === 'horizontal';

        const length = horizontal ? numberCodeLength * 4 : 5;

        const letters = ['א', 'ב', 'ג', 'ד', 'ה'];

        for (let i = 0; i < length; i++) {
            matriza.push(<div key={i + 1}
                style={{
                    color: '#fff',
                    width: horizontal ? '11.1111%' : 'auto',
                    height: horizontal ? 'auto' : '20%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    fontSize: font_size.md,
                    padding: horizontal ? `${gutters.sm} 0` : `0 0 0 ${gutters.sm}`
                }}>
                {horizontal ? i + 1 : letters[i]}
            </div>
            );
        }

        return matriza;
    }

    render() {
        const horizontal = this.props.direction === 'horizontal';
        return (
            <Element display={horizontal ? 'flex' : 'block'} paddingRight={horizontal && '3rem'}>
                {this.buildMatriza()}
            </Element>
        )
    }
}


const
    Number = styled.div`
        display: flex;
        flex-wrap: wrap;
        background: #ffffff;
        .cell-image {
            overflow: hidden;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat; 
            width: 100%;
            height: 100%;
        }
        &:first-child {
            .cell {
                &:nth-child(4n) {
                    width: 1px;
                    visibility: hidden;
                    opacity: 0;
                    padding: 0;
                }
            }
        }
    `,
    ItemStyle = styled.div`
        width: 25%;
        height: 3rem;
        position: relative;
        opacity: 1;
        background-color:#fff;
        // border: 1px solid #999;
        .mark {
           opacity: 1;
        }
        @media screen and (min-width: ${Viewport.xs}) {
            height: ${props => props.numberCodeLength === 3 && '3.4rem'};
        }
        @media screen and (min-width: ${Viewport.xs_l}) {
            height: 5.2rem;
        }
        @media screen and (min-width: ${Viewport.sm}) {
            height: 7rem;
        }
        @media screen and (min-width: ${Viewport.md}) {
            height: 7.2rem;
        }
        @media screen and (min-width: ${Viewport.lg}) {
            height: 9.9rem;
        }
        @media screen and (min-width: ${Viewport.wide}) {
            height: 11.9rem;
        }
    `;
const cover = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    fontSize: '2.5rem',
    color: '#000',
    backgroundColor: 'rgba(255, 255, 255, .9)',
    transition: 'opacity .3s',
    fontWeight: 'bold',
    border: '.2rem solid #000'
};

export default TifzoretBoard;
