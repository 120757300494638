import React from 'react';
import { Link } from 'react-router-dom';
import { HBox, VBox, Element } from 'react-stylesheet';



 
import { font_family } from '../../00_base/variables';
import { Video, Paragraph, Button } from '../../01_atoms';
import { Container } from 'react-grid-system';
import Iframe from '../../01_atoms/iframe';



const ToolVideo = ({ url }) => {
    const convertYoutubeUrl = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/,
            match = url.match(regExp);
        return match && match[2].length === 11 ? match[2] : 'error';
    }
    
    const fixedVideoUrl = url && convertYoutubeUrl(url);

    return (
        <HBox background={'#000'} alignItems={'center'} style={{flex:1}}>
            <Container className={'d-flex flex-wrap align-items-center justify-content-center pt-0'}
                style={{width: '100%' }}>
                {/* {fixedVideoUrl && <Iframe src={fixedVideoUrl} />} */}
                {fixedVideoUrl && <Iframe src={url} width={'100%'} height={'280px'} style={{ width: '100%' }} />}
            
            <HBox justifyContent={'center'}>
                <Button variant={'secondary'}
                    block
                    color={'#fff'}
                    before={{ deg: 2, origin: 'right', direction: 'Y' }}
                    width={'200px'}>
                    <Link to={'/menu'} style={{color: 'inherit'}}>
                        המשך
                    </Link>
                </Button>
            </HBox>
            </Container>
        </HBox>
    )

}

export default ToolVideo;

