import React from 'react';
import { Element } from 'react-stylesheet';
import styled, {keyframes} from 'styled-components';

const Feed = ({ items, spaceBetweenItems, padding }) => {
    return (
        <Element className={'feed'} padding={padding}>
            {items.map((item, index) => (
                <FeedItem
                    key={index}
                    className={'feed-item'}
                    animationDelay={index * .2}
                    marginTop={spaceBetweenItems}
                    marginBottom={spaceBetweenItems}>
                    {item}
                </FeedItem>))}
        </Element>
    )
}

const fadeIn = keyframes`
    from {opacity: 0; transform: translateY(30px)}
    to {opacity: 1; transform: translateY(0)}
`;
const fadeOut = keyframes`
    from {opacity: 1; transform: translateY(0)}
    to {opacity: 0; transform: translateY(30px)}
`;
const FeedItem = styled.div`
    margin-top: ${props => props.marginTop};
    margin-bottom: ${props => props.marginBottom};
    opacity: 0;
    .active & {
        animation: .3s ${fadeIn} forwards;
        animation-delay: ${props => 1.2 + props.animationDelay + 's'};
    }
    .closing & {
        opacity: 1;
        animation: .3s ${fadeOut} forwards;
        animation-delay: ${props => props.animationDelay + 's'};
    }
`;


export default Feed;