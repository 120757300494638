import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-grid-system';
import { Element } from 'react-stylesheet';
import PropTypes from "prop-types";
import { gutters, colors } from '../../00_base/variables';


const ToolGallery = ({ items }) => {
    console.log(items);
    return (
        <div>
            <Container>
                {items.map((item, i) => {
                    return (
                        <ImageWrapper key={i}>
                            <Cover style={{ padding: !!item.cover ? '20px' : '0', backgroundImage: `url(${!!item.cover ? item.url ? item.url : item : null})` }}>
                                <img src={!!item.cover ? item.cover : item.url ? item.url : item} style={{ width: '100%' }} />
                            </Cover>
                        </ImageWrapper>
                    )
                })}
            </Container>
        </div>
    )
};

ToolGallery.defaultProps = {
    width: '100%',
    items: []
};

ToolGallery.propTypes = {
    items: PropTypes.array
};

const ImageWrapper = styled.div`
    display: flex;    
    margin-bottom: ${gutters.sm};
    margin-top: ${gutters.sm};
`;

const Cover = styled.div`
    width: 100%;
    position: relative;
    background-color: ${colors.white};
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`;

const pending = {
    width: '100%',
    height: '35rem',
    backgroundColor: 'rgba(153, 153, 153, .5)'
};

export default ToolGallery;