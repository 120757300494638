import React from 'react';

import { HBox, VBox, Element } from 'react-stylesheet';

import { Button, Text, Circle, Image, Heading, Span, BackgroundImage } from '../../01_atoms';
import { colors, gutters, font_size } from '../../00_base/variables';
import { Container } from 'react-grid-system';
import { Form } from '../../03_organisms';
import FINAL_IMAGE from '../../../assets/images/trivia_final.jpg';
import { Link } from '@reach/router';

import TRIVIA_BACKGROUND from '../../../assets/images/trivia/trivia_background.jpg';
import TRIVIA_ANSWER from '../../../assets/images/trivia/trivia_answer.png';
import TRIVIA_QUESTION from '../../../assets/images/trivia/trivia_question.png';
import TRIVIA_CONTINUE from '../../../assets/images/trivia/trivia_continue.png';

class ToolTrivia extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            theme: [],
            activeQuestion: 0,
            markSections: ['', '', '', '', '', '', '', '', '', ''],
            isLoading: true
        };

        this.checkAnswer = this.checkAnswer.bind(this);
        this.markQuestion = this.markQuestion.bind(this);
        this.goToQuestion = this.goToQuestion.bind(this);
        this.checkQuestionsValidation = this.checkQuestionsValidation.bind(this);
    }

    markQuestion(answerIndex, questionIndex) {
        let { markSections } = this.state;
        markSections[questionIndex] = answerIndex;
        this.setState({
            markSections: markSections
        });

        this.checkQuestionsValidation()
    }

    goToQuestion(index) {
        this.setState({
            activeQuestion: index,
            checkAnswersLevel: this.state.allValid && index >= this.props.items.length
        })
    }

    checkAnswer(event) {
        const
            answer = event.target.getAttribute('data-answer'),
            index = event.target.getAttribute('data-index'),
            section = event.target.getAttribute('data-section');

        if (answer === index) {
            console.log('1')
        } else {
            console.log('0');
        }

        this.state.markSections.push({ section: section, index: index });

        this.setState({
            markSections: this.state.markSections
        })
    }

    checkQuestionsValidation() {
        this.setState({
            allValid: this.state.markSections.slice(0, this.props.items.length).filter(section => section === '').length === 0
        })
    }

    render() {
        const { allValid, activeQuestion, markSections, checkAnswersLevel, isLoading, privateName } = this.state;
        const { toolData } = this.props;
        return (
            <BackgroundImage url={TRIVIA_BACKGROUND} style={{ flex: 1,  }}>
                <Container>
                    <VBox style={{maxWidth: '360px', margin: '0 auto'}}>
                        {/* {checkAnswersLevel &&
                            <Heading tag={'h3'}
                                color={'#fff'}
                                textAlign={'center'}
                                marginTop={'10rem'}
                                marginBottom={'4rem'}
                                paddingLeft={gutters.sm}
                                paddingRight={gutters.sm}
                                fontWeight={'bold'}>
                                {'האם יש לכם את מה שנדרש כדי לפתור את החידה?'}
                            </Heading>
                        } */}

                        {this.props.items &&
                            <ColorsIndicators
                                items={this.props.items.map((item, i) => {
                                    return { color: item.color, answer: item.answer }
                                })}
                                toolAnswer={this.props.answer}
                                markSections={markSections}
                                goToQuestion={this.goToQuestion}
                                active={activeQuestion}
                                checkAnswersLevel={checkAnswersLevel}
                                isLoading={isLoading}
                                adventureId={this.state.adventureId}
                                allValid={allValid} />}

                        {!checkAnswersLevel && this.props.items &&
                            <TriviaQuestion {...this.props.items[activeQuestion]}
                                privateName={privateName}
                                index={activeQuestion}
                                markAnswer={markSections[activeQuestion]}
                                goToQuestion={this.goToQuestion}
                                markQuestion={this.markQuestion} />}
                    </VBox>
                </Container>
            </BackgroundImage>
        )
    }
}

class TriviaQuestion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            markAnswer: this.props.markAnswer
        };

        this.markAnswer = this.markAnswer.bind(this);
        this.getQuestionIndexName = this.getQuestionIndexName.bind(this);
    }

    componentDidMount() {
        this.setState({
            markAnswer: this.props.markAnswer
        });
    }

    markAnswer(e) {
        const answerIndex = e.target.getAttribute('data-index');

        this.props.markQuestion(answerIndex, this.props.index);
    }

    getQuestionIndexName(index) {
        let questionIndex = '';
        switch (index) {
            case 1: questionIndex = 'ראשונה'; break;
            case 2: questionIndex = 'שנייה'; break;
            case 3: questionIndex = 'שלישית'; break;
            case 4: questionIndex = 'רביעית'; break;
            case 5: questionIndex = 'חמישית'; break;
            case 6: questionIndex = 'שישית'; break;
            case 7: questionIndex = 'שביעית'; break;
            case 8: questionIndex = 'שמינית'; break;
            case 9: questionIndex = 'תשיעית'; break;
            case 10: questionIndex = 'עשירית'; break;
        }

        return questionIndex;
    }

    render() {
        const { color, text, question, answer, options, index, markAnswer, privateName } = this.props;
        console.log('answer', answer);
        return (
            <VBox>

                <VBox alignItems={'center'} justifyContent={'center'} padding={10} style={{ background: `url(${TRIVIA_QUESTION}) center center /contain` }} height={'190px'}>
                    <Heading tag={'h3'} textAlign={'center'} fontWeight={'bold'} color={colors.black}>
                        {`שאלה ${this.getQuestionIndexName(index + 1)}`}
                    </Heading>
                    <Heading
                        tag={'h4'}
                        textAlign={'center'}
                        fontWeight={'bold'}
                        color={colors.black}
                        style={{ marginTop: 0 }}>
                        {question}
                    </Heading>
                </VBox>

                <HBox justifyContent={'space-between'} flexWrap={'wrap'}>
                    {options.map((option, o) => {
                        const isActive = parseInt(markAnswer) === o;

                        return (
                            <Element key={o}
                                width={'50%'}
                                data-answer={answer}
                                padding={'1rem'}
                                fontWeight={'bold'}>
                                <HBox justifyContent={'center'}
                                    padding={'40px 5px'}
                                    minHeight={'160px'}
                                    alignItems={'center'}
                                    background={`url(${TRIVIA_ANSWER}) center center no-repeat / 100%`}
                                    color={!isActive ? '#000000' : '#000000'}
                                    border={isActive ? '2px solid #000000' : '2px solid transparent'}
                                    width={'100%'} height={'100%'}
                                    onClick={this.markAnswer}
                                    fontSize={font_size.lg}
                                    textAlign={'center'}
                                    fontWeight={'bold'}
                                    data-index={o}>
                                    {/* <Text color={isActive ? color : '#ffffff'}
                                        style={{
                                            position: 'absolute',
                                            top: '.5rem',
                                            right: gutters.sm,
                                        }}>{o + 1}</Text> */}
                                    {option}
                                </HBox>
                            </Element>
                        )
                    })}
                </HBox>

                <Element padding={gutters.sm} margin={`${gutters.md} 0`}>
                    <Button block
                        style={{ backgroundColor: 'transparent'}}
                        onClick={() => markAnswer && this.props.goToQuestion(index + 1)}>
                            <img src={TRIVIA_CONTINUE} width={'100%'}/>
                        </Button>
                </Element>
            </VBox>
        )
    }
}

class ColorsIndicators extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            answerSuccess: false,
            error: false
        }
    }

    formHandler = (formData) => {
        if (formData.user_answer === this.props.toolAnswer) {
            this.setState({
                answerSuccess: true,
                error: false
            })
        } else {
            this.setState({
                error: true,
                answerSuccess: false
            })
        }
    }

    render() {
        const { items, active, markSections, allValid, checkAnswersLevel, goToQuestion } = this.props;
        const { answerSuccess } = this.state;
        let countRightAnswers = 0;
        console.log("allValid", allValid)
        const circles = items.map((item, i) => {
            const answer = item.answer;
            const userAnswer = parseInt(markSections[i]);
            const color = item.color;
            const rightAnswer = answer === userAnswer;

            if (rightAnswer) {
                countRightAnswers++;
            }
            const isActive = active === i;
            console.log('countRightAnswers', countRightAnswers);
            console.log('items.length', items.length);
            return (
                <Circle key={i}
                    size={checkAnswersLevel ? 50 : 25}
                    border={`.2rem solid ${isActive || rightAnswer ? '#fff' : checkAnswersLevel ? '#ff0000' : '#999'}`}//  active === i ? !checkAnswersLevel && '.2rem solid #e21737' : rightAnswer ? '.2px solid #fff' : checkAnswersLevel ? `.2rem solid #e21737` : markSections[i] !== '' && `.2rem solid #e21737`
                    style={{ margin: `${gutters.sm} 0` }}
                    onClick={() => goToQuestion(i)}
                    background={color}>
                    <Span fontWeight={'bold'}
                        color={colors.black}
                        fontSize={rightAnswer ? font_size.md : font_size.sm}>
                        {markSections[i] !== '' && userAnswer + 1}
                    </Span>
                </Circle>
            )
        });
        return (
            <Element padding={`${gutters.sm} 0`} >
                {!answerSuccess && countRightAnswers < items.length &&
                    <HBox justifyContent={'space-around'} flexWrap={checkAnswersLevel ? 'wrap' : 'nowrap'}>
                        {circles}
                    </HBox>
                }
                {checkAnswersLevel ?
                    countRightAnswers === items.length ?

                        <Element width={'100%'} textAlign={'center'} marginTop={gutters.lg}>
                            <VBox justifyContent={'center'} alignItems={'center'} style={{background: `url(${TRIVIA_QUESTION}) center center /cover` }} height={'320px'}>
                                <Heading tag={'h2'} color={colors.black}>אימות המערכת בוצע בהצלחה.</Heading>
                                <Heading tag={'h3'} color={colors.black}>לקבלת קוד הגישה הביטו בתמונה</Heading>
                            </VBox>
                            <Element marginTop={gutters.lg}>
                                <Image src={FINAL_IMAGE} />
                            </Element>
                            <Button block style={{ backgroundColor: 'transparent'}}>
                            <img src={TRIVIA_CONTINUE} width={'100%'}/>
                            </Button>
                        </Element>
                        :
                        <VBox justifyContent={'center'} alignItems={'center'} width={'100%'} textAlign={'center'} marginTop={gutters.lg} 
                        style={{background: `url(${TRIVIA_QUESTION}) center center /cover` }} height={'320px'}>
                            <Heading tag={'h2'} color={colors.black}>{countRightAnswers === 0 ? 'מממ... כדאי לנסות שוב' : countRightAnswers < items.length - 6 ? 'נסו שוב.' : countRightAnswers < items.length - 3 ? 'אתם בכיוון הנכון' : countRightAnswers < items.length ? 'כמעט!' : 'כל הכבוד!'}</Heading>
                            <Heading tag={'h3'} color={colors.black}>{`עניתם נכון על ${countRightAnswers} מתוך ${items.length} השאלות`}</Heading>
                            {/* <Heading tag={'h4'} color={colors.white}>פיתרון המשוואה יביא לכם הסיסמא לחידה הבאה!</Heading> */}
                            <Button block onClick={() => this.props.goToQuestion(0)} style={{ backgroundColor: 'transparent'}}>
                            <img src={TRIVIA_CONTINUE} width={'100%'}/>
                            </Button>
                        </VBox>
                    :
                    <Circle size={25} background={allValid ? '#f4f4f4' : '#d8d8d8'} style={{ display: 'none' }}>
                        {/* {allValid ? <IconCheckmark width={'7px'} height={'12px'} borderWidth={'3px'}/> :
                            <IconLock width={'25px'} height={'14px'} color={'#7f7f7f'}/>} */}
                    </Circle>
                }

            </Element>
        )
    }
}

export default ToolTrivia;
