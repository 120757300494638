import { colors } from '../../../00_base/variables/v-colors';
import { gutters } from '../../../00_base/variables/v-gutters';
import { font_size } from '../../../00_base/variables';

const fieldStyle = {
    control: {
        width: '100%',
        marginBottom: gutters.lg,
    },
    label: {
        color: '#fff',
        fontSize: font_size.md,
        marginBottom: gutters.xs
    },
    input: {
        padding: `${gutters.sm} 0`,
        color: colors.white,
        borderWidth: 0,
        borderBottomWidth: '.1rem',
        borderBottomColor: colors.border,
        backgroundColor: 'transparent',
        outline: 'none'
    },
    horizontal: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    vertical: {
        display: 'flex',
        flexDirection: 'column',
    }
};

export default fieldStyle;