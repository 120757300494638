import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../../00_base/variables/v-colors';
import { gutters } from '../../../00_base/variables/v-gutters';
import Label from '../../../01_atoms/text/a-label';
import Span from '../../../01_atoms/text/a-span';
import fieldStyle from '../field-style/m-field-style';

const TOGGLE_CHECKBOX = 'TOGGLE_CHECKBOX';

const inputReducer = (state, action) => {
    switch (action.type) {
        case TOGGLE_CHECKBOX:
            let updatedState;
            if (state.value.find(opiton => opiton === action.value)) {
                updatedState = state.value.filter(option => option !== action.value);
            } else {
                updatedState = state.value.concat(action.value);
            }
            
            return {
                value: updatedState,
                isValid: action.isValid
            }
        default:
            return state
    }
}

const FieldCheckbox = ({
    name,
    label,
    options,
    dir,
    required,
    errorMessage,
    changeHandler,
    initialValue,
    initiallyValid,
}) => {
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: initialValue,
        isValid: initiallyValid,
    })

    useEffect(() => {
        changeHandler(name, inputState.value, inputState.isValid);
    }, [inputState, changeHandler, name]);

    const handleInputChange = event => {
        const value = event.target.value;
        let isValid = true;
        if (required && value.trim().length === 0) { isValid = false; }

        dispatch({
            type: TOGGLE_CHECKBOX,
            value: value,
            isValid: isValid
        })
    };

    return (
        <div style={fieldStyle.control}>
            <Label style={fieldStyle.label} value={label} />

            <div style={{ ...checkboxesContainer, ...fieldStyle[dir] }}>
                {options.map((option, index) => {
                    return <div key={index} style={{ ...fieldStyle.input, ...checkboxWrapper }}>
                        <label style={checkboxLabel}>
                            <input
                                checked={!!inputState.value.find(item => item === option) && 'checked'}
                                type="checkbox"
                                value={option}
                                onChange={handleInputChange}
                                name={name} />
                            {option}
                        </label>
                    </div>
                })}
            </div>

            {!inputState.isValid && <Span value={errorMessage} />}
        </div>
    )
}

const
    checkboxesContainer = {

    },
    checkboxWrapper = {
        display: 'flex',
        marginRight: '1rem',
        padding: 0
    },
    checkboxLabel = {
        display: 'flex',
    };

FieldCheckbox.defaultProps = {
    value: '',
    initialValue: [],
    initiallyValid: true,
    dir: 'horizontal'
};

FieldCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.array.isRequired,
    dir: PropTypes.oneOf(['horizontal', 'vertical']),
    errorMessage: PropTypes.string,
    changeHandler: PropTypes.func.isRequired,
    initialValue: PropTypes.array,
    initiallyValid: PropTypes.bool,
    required: PropTypes.bool,
};

export default FieldCheckbox; 