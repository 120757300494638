import React, { useState } from 'react';
import { Button, Heading, Paragraph } from '../../01_atoms';
import { Element, VBox, HBox } from 'react-stylesheet';
import { colors, font_size, gutters, zIndex } from '../../00_base/variables';

const ToolClues = ({ cluesData, clueIndex, close, isActive }) => {
    const [activeClue, setActiveClue] = useState(null);
    const [availableItems, setAvailableItems] = useState([0]);
    console.log('cluesData', cluesData);
    console.log('activeClue', activeClue);
    return (
        <HBox className={'asdsadsa'}
            width={'100%'}
            height={'100vh'}
            background={'#136a8a'}
            justifyContent={'center'}
            alignItems={'center'}
            position={'absolute'}
            zIndex={zIndex.max}
            top={0}
            left={0}
            style={{ transition: 'transform .4s', transform: `translateY(${isActive ? 0 : '-100vh'})` }}>
            <Button
                radius={'10px'}
                onClick={() => close(() => setActiveClue(false))}
                style={{ position: 'absolute', top: '10px', left: '10px', backgroundColor: colors.secondary }}>
                חזור
            </Button>

            {!activeClue && activeClue !== 0 && cluesData.map((clue, index) => {
                const isAvailable = availableItems.some(item => item === index);
                return (
                    <Element key={index} padding={gutters.sm} textAlign={'center'}>
                        <Button
                            onClick={() => isAvailable && setActiveClue(index)}
                            style={{ backgroundColor: colors.white, color: colors.black, opacity: isAvailable ? 1 : .4, fontSize: font_size.md }}>
                            {index === 2 ? 'פתרון' : `רמז ${index + 1}`}
                        </Button>
                    </Element>
                )
            })}


            {!!cluesData[activeClue] &&
                <Element padding={gutters.sm} textAlign={'center'}>
                    <Heading
                        color={'#fff'}
                        fontSize={font_size.jumbo}>
                        {cluesData[activeClue].title}
                    </Heading>
                    <Paragraph
                        color={'#fff'}
                        fontSize={font_size.md}>
                        {cluesData[activeClue].description}
                    </Paragraph>
                    <Button 
                    style={{ backgroundColor: colors.white, color: colors.black, fontSize: font_size.md }}
                        onClick={() => {
                            setAvailableItems([...availableItems, activeClue + 1]);
                            
                            close(() => setActiveClue(false));
                        }}>
                        המשך
                    </Button>
                </Element>
            }
        </HBox>
    )
}

export default ToolClues;