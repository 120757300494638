import React from 'react';
import PropTypes from 'prop-types';
import {colors, font_size} from "../../00_base/variables";

const Heading = ({tag, color, textAlign, textDecoration, fontSize, fontWeight, style, value, children}) => {
    return React.createElement(
        tag,
        {style: {
            color, fontSize: fontSize || font_size[tag], fontWeight: fontWeight, textAlign, textDecoration, ...style
        }},
        children || value
    )
};

Heading.defaultProps = {
    tag: 'h2',
    color: colors.gray_10,
    value: ''
};

Heading.propTypes = {
    tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    color: PropTypes.string,
    style: PropTypes.object,
    textDecoration: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element])
};

export default Heading;