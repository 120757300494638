import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import PropTypes, { oneOfType } from "prop-types";
import { MdFileUpload, MdCamera, MdEdit } from "react-icons/md";
// import SimpleLoader from '../loaders/simple-loader';
import { gutters } from '../../../00_base/variables/';
import { BackgroundImage } from '../../../01_atoms/';
import TailLoader from '../../../01_atoms/loaders/tail-loader/';




const FileUploader = ({ apiUrl, name, value, singleFile, changeHandler, previewWidth, previewHeight, previewStyle, submitTrigger, triggerSize, TriggerIcon, fileUploaded, setIsFileLoading, buttonWidth, buttonBackgroundColor, buttonColor }) => {
    let fileInput = null;

    const [cropperActive, setCropperActive] = useState(false);
    const [isLoading, setIsLoading] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const fileSelectedHandler = event => {

        const files = [];

        for (let i = 0; i < event.target.files.length; i++) {
            files.push(event.target.files[i]);
        }

        setSelectedFiles(singleFile ? files : selectedFiles.concat(files));

        if (!submitTrigger) {
            fileUploadHandler(files)
        }

    };

    const fileUploadHandler = async (array) => {

        for (let i = 0; i < array.length; i++) {
            await uploadSingleFile(array[i], i);
        }
        setIsLoading(null);
        console.log('Done!');
    };

    const fixFileName = (fileName) => {
        const extension = /(?:\.([^.]+))?$/.exec(fileName)[1];
        return `${fileName.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.${extension}`;
    };
    const uploadSingleFile = async (item, index) => {
        setIsLoading(index);
        setCropperActive(false);
        const formData = new FormData();
        formData.append('file', item, fixFileName(item.name));

        await upload(formData)

        console.log(formData);
    };

    const upload = (formData) => {
        setIsLoading(true);
        setIsFileLoading(true);
        return new Promise(resolve => {
            fetch(`${apiUrl}/upload_image`, {
                method: 'POST',
                body: formData
            }).then(
                response => response.json()
            ).then(
                success => {
                    const files = success.files.map(img => { return `${apiUrl}/images/${img}` });
                    fileUploaded(files);
                    setCropperActive(false);
                    setIsLoading(false);
                    setIsFileLoading(false);
                    changeHandler(name, files[0], true)
                }
            ).then(
                error => console.log('error', error)
            );
        }
        )
    };


    const renderPreviews = selectedFiles.map((file, i) => {
        return (
            <PreviewFile key={i} size={previewWidth}>
                <BackgroundImage
                    width={previewWidth}
                    height={previewHeight}
                    radius={'10px'}
                    url={value ? value : URL.createObjectURL(file)}
                    cover={{ component: <div>vda</div> }}>
                    {isLoading === i && '.......'}
                </BackgroundImage>
            </PreviewFile>
        )
    });

    // if (cropperActive) {
    //     return (
    //         <div style={{ width: '100%' }}>
    //             <ImageCropper
    //                 src={value}
    //                 classes={{ cropContainer: 'crop-container' }}
    //                 handleSubmit={dataUrl => {
    //                     console.log('dataIrl', dataUrl);
    //                     const pic = dataUrl.replace(/^data:image\/(png|jpg);base64,/, "")
    //                     console.log('pic', pic);
    //                     setIsLoading(true);
    //                     function dataURLtoFile(dataurl, filename) {

    //                         let arr = dataurl.split(','),
    //                             mime = arr[0].match(/:(.*?);/)[1],
    //                             bstr = atob(arr[1]), 
    //                             n = bstr.length, 
    //                             u8arr = new Uint8Array(n);

    //                         while(n--){
    //                             u8arr[n] = bstr.charCodeAt(n);
    //                         }

    //                         return new File([u8arr], filename, {type:mime});
    //                     }

    //                     const file = dataURLtoFile(pic, 'croped.jpg');

    //                     uploadSingleFile(file);
    //                     console.log(file);

    //                     // const file = new File([blob], "my_image.png", { size: 250, type: "image/png", lastModified: new Date() })
    //                     // const files = [file];

    //                     // setSelectedFiles(files);
    //                     // if (!submitTrigger) {
    //                     //     fileUploadHandler(files)
    //                     // }

    //                 }} />
    //         </div>
    //     )
    // }

    return (
        <div>
            <input
                multiple={!singleFile}
                name={'image'}
                capture="user"
                style={{ display: 'none' }}
                type="file"
                onChange={fileSelectedHandler}
                ref={input => fileInput = input} />

            <FilesContainer>

                {selectedFiles.length > 0 && renderPreviews}

                {isLoading && <div style={loaderWrapper}>
                    <TailLoader />
                </div>}

                {!isLoading &&
                    <PreviewFile size={buttonWidth} previewMode={selectedFiles.length > 0}>
                        <AddFileButton
                            previewMode={selectedFiles.length > 0}
                            onClick={() => fileInput.click()}
                            size={buttonWidth}
                            backgroundColor={buttonBackgroundColor}
                            color={buttonColor} >

                            {selectedFiles.length > 0 ?
                                <MdFileUpload size={selectedFiles.length > 0 ? 35 : triggerSize} className={'trigger'} /> :
                                <TriggerIcon size={selectedFiles.length > 0 ? 35 : triggerSize} className={'trigger'} />
                            }
                        </AddFileButton>
                    </PreviewFile>
                }
                {/* {selectedFiles.length > 0 && !isLoading &&
                    <EditFileButton 
                        previewMode={selectedFiles.length > 0}
                        onClick={() => setCropperActive(true)}
                        size={buttonWidth}
                        backgroundColor={buttonBackgroundColor}
                        color={buttonColor} >
                        <MdEdit size={selectedFiles.length > 0 ? 35 : triggerSize} className={'trigger'} />
                    </EditFileButton>
                } */}
            </FilesContainer>


            {!!submitTrigger &&
                <SubmitButton size={buttonWidth}
                    onClick={fileUploadHandler}>
                    Upload
                </SubmitButton>
            }
        </div>
    )
};

const buttonSize = (size) => {
    return { width: size, height: size }
};

const loaderWrapper = {
    backgroundColor: 'rgba(255,255,255,.7)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};
const FilesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -${gutters.sm} ${gutters.md};
    position: relative;
`;
const PreviewFile = styled.div`
    ${props => buttonSize(props.size)};
    ${props => props.previewMode && `
        position: absolute;
        right: 10px;
        top: 10px;
    `};
    // padding: $ {Gutter.sm};
    overflow: hidden;
    outline: 0;
`;
const AddFileButton = styled.button`
    ${props => buttonSize(props.size)};
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    border-radius: 10px;
    border: 0;
    outline: 0 !important;
    ${props => props.previewMode && `
        background-color: #f4f4f4;
        border: 2px solid #000;
        border-radius:50%;
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
    `};
`;

const EditFileButton = styled.button`
    ${props => buttonSize(props.size)};
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    border-radius: 10px;
    border: 2px solid #000;
    outline: 0 !important;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #f4f4f4;
    border-radius:50%;
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
    align-items: center;
`;
const SubmitButton = styled.button`
`;

FileUploader.defaultProps = {
    singleFile: false,
    buttonWidth: '120px',
    apiUrl: 'https://api.youdoadventures.com',
    TriggerIcon: MdFileUpload,
    triggerSize: 60,
    submitTrigger: false
};
FileUploader.propTypes = {
    apiUrl: PropTypes.string.isRequired,
    singleFile: PropTypes.bool,
    buttonWidth: PropTypes.string,
    previewStyle: PropTypes.object,
    TriggerIcon: PropTypes.element,
    submitTrigger: PropTypes.bool,
    triggerSize: oneOfType([PropTypes.string, PropTypes.number])
};


export default FileUploader;