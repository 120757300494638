import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Page, AppFooter } from '../../04_layout';
import { Image, Heading, Circle, Button, Paragraph, Span } from '../../01_atoms';
import { Link } from 'react-router-dom';
import LOGO from '../../../assets/images/logos/youdo_logo_white.png';
import { VBox, Element } from 'react-stylesheet';
import { gutters, colors, font_size } from '../../00_base/variables';

import BACKGROUND from '../../../assets/images/islam_background.jpg';


const STARTER_VIDEO = true;

const ScreenHomepage = (props) => {
    const [counting, setCounting] = useState(null);
    localStorage.setItem('confirmedPassword', JSON.stringify([-1]));

    localStorage.setItem('hideClues', props.hideClues ? "true" : "false");
    useEffect(() => {
        if (counting === 0) {
            window.location.href = STARTER_VIDEO ? '/tool/תדריך%20סודי' : '/menu';
        } else if (counting >= 1) {
            setTimeout(() => setCounting(counting - 1), 1000);
        }
    }, [counting]);
    return (
        <Page {...props} style={{ background: `url(${BACKGROUND}) center center /cover` }} >
            <audio id={'player'} src={'https://admin.youdoadventures.com/wp-content/uploads/2019/04/start_sequence_audio.mp3'} autoPlay={true} />
            <VBox justifyContent={'center'} alignItems={'center'} minHeight={'100%'} width={'100%'} padding={`0 ${gutters.md}`} maxWidth={'500px'} margin={'0 auto'}>
                <Heading tag={'h2'} textAlign={'center'} color={colors.white} style={{ marginBottom: 0 }}>המוזיאון לאמנות האסלאם מציג:</Heading>
                <Heading tag={'h1'} textAlign={'center'} color={colors.white} style={{ marginBottom: 0 }}>שם קוד: מחוגי הגורל</Heading>


                <Button
                    block
                    onClick={() => setCounting(3)}
                    style={{ fontSize: font_size.lg, marginTop: '30px', color: colors.white, backgroundColor: colors.primary }} >
                    מוכנים?
                </Button>

                <Element marginTop={gutters.md}>
                    <Image src={LOGO} width={160} />
                </Element>

                {!!counting &&
                    <Circle size={200} style={{ position: 'absolute', border: `3px solid ${colors.white}` }} background={colors.black}>
                        <Span fontSize={font_size.max} color={'#fff'}>{counting}</Span>
                    </Circle>
                }
            </VBox>
        </Page>
    )
};

ScreenHomepage.default = {
    title: 'ברוכים הבאים',
};
ScreenHomepage.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

export default ScreenHomepage;