import React from 'react';
import { HBox, Element } from 'react-stylesheet';
import { Container } from 'react-grid-system';
import { Circle, Span, Image } from '../../01_atoms';

const ToolTranslator = ({items}) => {
    const originalLetters = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט', 'י', 'כ', 'ל', 'מ', 'נ', 'ס', 'ע', 'פ', 'צ', 'ק', 'ר', 'ש', 'ת'];

    const renderLetter = (symbol, i) => {
        const letter = originalLetters[i];
        return (
            <HBox justifyContent={'center'} alignItems={'center'} padding={'2rem'} width={'50%'}
                border={'1px solid #ffffff'} background={'#63819f'}>
                <Span style={{ position: 'absolute', top: '1rem', right: '1rem', fontWeight: 'bold', color: '#ffffff' }}>{letter}</Span>
                <Element width={'60px'} height={'60px'}>
                        <Image src={symbol} width='60px' />
                </Element>
            </HBox>
        )
    };

    return (
        <Element background={'#fff'}>
            
                <HBox flexWrap='wrap'>
                    {items.map(renderLetter)}
                </HBox>
            
        </Element>
    )
}

export default ToolTranslator;
